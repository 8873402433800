import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconClose } from '../../Components/Icons/Close.svg';
import { ReactComponent as IconNotificationActive } from '../../Components/Icons/NotificationActive.svg';
// import {ReactComponent as IconStatus} from '../../Components/Icons/Status.svg';
import * as bindEventsActions from '../../store/actions/eventsActions';
import * as bindSessionActions from '../../store/actions/sessionActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Scrollbars } from 'react-custom-scrollbars';
import { Avatar } from '../../Components/Avatar';
import __ from '../../utils/translate'

class Notifications extends Component {
  componentDidMount() {
    this.initialLoad();
    // this.navigationEventListener = Navigation.events().bindComponent(this);
  }

  initialLoad() {
    this.props.eventsActions.load();
    this.props.eventsActions.read();
  }

  fetchNotifications = () => {
    const { events, eventsActions } = this.props;
    if (events.isFetching || !events.hasMore) {
      return;
    }
    eventsActions.loadMore({ ...{ page: events.page } });
  };

  viewProfile = (id, eventId) => {
    this.props.eventsActions.load();
    this.props.close();
    this.props.history.push({ pathname: `/${id}`, state: { modal: true, initialRoute: '/', source: 'notifications' } });
  };

  renderNotifications() {
    return this.props.events.results.map((item) => {
      return (
        <div
          onClick={() => this.viewProfile(item.user.user_id, item.id)}
          key={item.id}>
          <li className={item.is_read ? 'dialog' : 'dialog new'}>
            <div className="dialog_photo avatar">
              <Avatar main_photo={item.user.main_photo} source="notifications" url={this.props.match.url} />
              {/*<div className="user_status">*/}
              {/*  <IconStatus />*/}
              {/*</div>*/}
            </div>
            <div className="dialog_content">
              <div className="dialog_title">
                <strong>{item.user.name}</strong> {item.type === 'visitor' ? __('viewed your profile') : __('added to favorite')}
              </div>
              <div className="dialog_location">
                {item.user.from_location}
              </div>
            </div>
            {!item.is_read ? <div className="new_notification"></div> : null}
          </li>
        </div>
      );
    });
  }

  render() {
    const { results, isFetching } = this.props.events;
    return (
      <div className="notifications">
        {
          !this.props.screen.isMobileMode && (
            <div className="btn_close notifications_close" onClick={this.props.close}>
              <IconClose/>
            </div>
          )
        }
        {
          !this.props.screen.isMobileMode && (
            <div className="notifications__title">
              <IconNotificationActive/>
              <span className="nav-menu__text">{__('Notifications')}</span>
            </div>
          )
        }
        <div className={this.props.screen.isMobileMode ? 'notifications_container' : ''} style={{overflow: 'hidden', height: '100%'}}>
          {this.props.screen.isMobileMode && (
            <p className="title-text title-text_notifications">
              {__('Notifications')}
            </p>
          )}
           <Scrollbars
              className="notifications__list"
              autoHeight
              autoHeightMin={100}
              autoHeightMax={'100%'}>
            {isFetching ? <p>{__('Loading...')}</p> : results && results.length ? (
              <>
                <InfiniteScroll
                  dataLength={this.props.events.results.length}
                  next={this.fetchNotifications}
                  hasMore={this.props.events.hasMore}
                  loader={__('Loading...')}>
                  {this.renderNotifications()}
                </InfiniteScroll>
                {this.props.screen.isMobileMode && (<div style={{height: 130}}></div>)}
              </>
            ) : (<p style={{ textAlign: 'center' }}>{__('No notifications at the moment.')}</p>)}
            </Scrollbars>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => ({
    events: state.events,
    screen: state.screen,
  }),
  (dispatch) => ({
    eventsActions: bindActionCreators(bindEventsActions, dispatch),
    sessionActions: bindActionCreators(bindSessionActions, dispatch),
  }),
)(Notifications));
