import React, {Component} from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {ReactComponent as Logo} from '../../Icons/Logo.svg';
import {ReactComponent as IconPeople} from '../../Icons/People.svg';
import {ReactComponent as IconFavorites} from '../../Icons/Favorites.svg';
import {ReactComponent as IconNotification} from '../../Icons/Notification.svg';
import {ReactComponent as IconMessages} from '../../Icons/Messages.svg';
import {ReactComponent as IconProfile} from '../../Icons/Profile.svg';
import {ReactComponent as IconPeopleActive} from '../../Icons/PeopleActive.svg';
import {ReactComponent as IconFavoritesActive} from '../../Icons/FavoritesActive.svg';
import {ReactComponent as IconProfileActive} from '../../Icons/ProfileActive.svg';
import {ReactComponent as IconMessagesActive} from '../../Icons/MessagesActive.svg';
import {ReactComponent as IconNotificationActive} from '../../Icons/NotificationActive.svg';
import {Backdrop} from '../../UI';
import {connect} from 'react-redux';
import Notifications from '../../../Containers/Notifications';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';

class MainMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: false,
    };
  }

  componentDidMount() {
    if (this.props.registration.inProgress === true) {
      this.props.registration.inProgress = false;
    }
  }

  openNotification = () => {
    this.setState({
      showNotification: true,
    });
    this.props.eventsActions.openModal(this.state.showNotification);
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
    this.props.eventsActions.closeModal(this.state.showNotification);
  };

  render() {
    const clsNotification = ['nav-menu__item'];

    const clsHeader = ['header', 'header__main'];

    if (this.state.showNotification) {
      clsNotification.push('active');
    }
    const showBlur = this.props.events.showModal || this.props.events.serverError
    if (showBlur) {
      clsHeader.push('blur');
    }

    if (this.props.events.results.filter(it => it.is_read === false).length > 0) {
      clsNotification.push('new-notifications');
    }
    return (
      <>
        <header className={clsHeader.join(' ')}>
          <div className="container">
            <nav>
              <Link className="header__logo" to="/">
                <Logo />
              </Link>
              <ul className="nav-menu">
                <li>
                  <NavLink
                    to="/"
                    exact={true}
                    activeClassName="active"
                    className="nav-menu__item">
                    <span className="no-active">
                      <IconPeople />
                    </span>
                    <span className="active">
                      <IconPeopleActive />
                    </span>
                    <span className="nav-menu__text">People</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/favorites"
                    activeClassName="active"
                    className="nav-menu__item">
                    <span className="no-active">
                      <IconFavorites />
                    </span>
                    <span className="active">
                      <IconFavoritesActive />
                    </span>
                    <span className="nav-menu__text">Favorites</span>
                  </NavLink>
                </li>
                <li onClick={this.openNotification}>
                  <div className={clsNotification.join(' ')}>
                    <span className="no-active">
                      <IconNotification />
                    </span>
                    <span className="active">
                      <IconNotificationActive />
                    </span>
                    <span className="nav-menu__text">Notifications</span>
                  </div>
                </li>
                <li>
                  <NavLink
                    to="/messages"
                    activeClassName="active"
                    className="nav-menu__item">
                    {this.props.session.unreadMessageCount ? (
                      <span className="count-new-messages">{this.props.session.unreadMessageCount}</span>
                    ) :null}
                    <span className="no-active">
                      <IconMessages />
                    </span>
                    <span className="active">
                      <IconMessagesActive />
                    </span>
                    <span className="nav-menu__text">Messages</span>
                  </NavLink>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: '/my-profile',
                      state: {modal: true},
                    }}
                    className="nav-menu__item">
                    <span className="no-active">
                      <IconProfile />
                    </span>
                    <span className="active">
                      <IconProfileActive />
                    </span>
                    <span className="nav-menu__text">Profile</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        {this.state.showNotification ? (
          <>
            <Notifications close={this.closeNotification}/>
            <Backdrop onClick={this.closeNotification} />
          </>
        ) : null}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      registration: state.registration,
      session: state.session
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(MainMenu),
);
