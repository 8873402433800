import React from 'react';
import ReactDOM from 'react-dom';
import './landing.css';
import './index.css';
import './responsive.css';
import './_slick.css';
import './_slick_theme.css';
import * as serviceWorker from './serviceWorker';
import './config/axiosConfig';
import store from './store';
import Root from './Components/Root';
import './i18n';

const app = <Root store={store} />;

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();