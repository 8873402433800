import React, { useState } from 'react';
import {Avatar} from '../../../Avatar';

const ContactsItem = (props) => {
  const {
    name,
    age,
    new_messages_count,
    location_country_text,
    location_city_text,
    last_message,
    main_photo,
    is_last_blur
  } = props.item;

  const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  }

  const [width] = useState(getRandomArbitrary(80, 220));
  const [left] = useState(getRandomArbitrary(0, 80));

  const clsLastMessage = ['dialog_preview'];

  const locationText = () => {
    if (location_city_text && location_country_text) {
      return `${location_city_text},\n${location_country_text}`;
    }
    if (location_city_text) {
      return location_city_text;
    }
    if (location_country_text) {
      return location_country_text;
    }
  };

  const hasUnread = () => {
    return new_messages_count > 0;
  };

  const clsDialog = ['dialog'];

  if (new_messages_count > 0) {
    clsDialog.push('new');
  }

  return (
    <li className={clsDialog.join(' ')} onClick={props.handleItemOnPress}>
      <Avatar extraClass="dialog_photo" source="chat" main_photo={main_photo} />
      <div className="dialog_content">
        <div className="dialog_title">
          <strong>
            {name}, {age}
          </strong>
        </div>
        <div className="dialog_location">{locationText()}</div>
        {is_last_blur ? (
          <div style={{ position: 'relative', overflow: 'hidden', width, height: 20 }}>
            <img alt="" src={'/img/blurpixel2.png'} style={{ position: 'absolute', left: -left, top: 0 }} /> 
          </div>
        ) : (
          <p className={clsLastMessage.join(' ')}>{last_message}</p>
        )}
      </div>
      {hasUnread() ? (
        <div className="new_message">{new_messages_count}</div>
      ) : null}
    </li>
  );
};

export default ContactsItem;
