import React from 'react';
import {Link} from "react-router-dom";
import {ReactComponent as IconStart} from '../Icons/Star.svg';
import {ReactComponent as IconClose} from '../Icons/Close.svg';
import {ReactComponent as MessagesActive} from '../Icons/MessagesActive.svg';

export default ({
  user,
  handleAddToFavorite,
  handleRemoveFromFavorite,
  fromFavorites,
}) => {
  const avatarUri = () => {
    const {photo_path, photo_id, photo_extension} = user.main_photo;
    return `${photo_path}/${photo_id}_huge.${photo_extension}`;
  };

  const photoSource = user.main_photo.has_main_photo
    ? {uri: avatarUri()}
    : null;

  const renderName = () => {
    const {name, age} = user;

    return (
      <div className="card_title">
        {name}, {age}
        {/*{props.isOnline ? (*/}
        {/*    <span className="card_status _online"></span>*/}
        {/*) : null}*/}
      </div>
    );
  };

  const renderAbout = () => {
    const {about} = user;
    if (!about) {
      return null;
    }
    return (
      <p className="card_message user_description">
        <span>{about}</span>
      </p>
    );
  };

  const locationText = () => {
    const {location_city_text, location_country_text} = user;
    if (location_city_text && location_country_text) {
      return (
        <span>
          {location_city_text}, <br /> {location_country_text}
        </span>
      );
    }
    if (location_city_text) {
      return location_city_text;
    }
    if (location_country_text) {
      return location_country_text;
    }
  };

  const clsFavorites = ['btn_round', 'btn_favorites'];

  if (user.is_favorite || fromFavorites) {
    clsFavorites.push('active');
  }

  return (
    <>
      <div className="card_img">
       <Link className="card_link" to={{
         pathname: `/${user.id}`,
         state: {modal: true, source: 'catalog'},
       }}></Link>
        {photoSource
          ? <img alt="" src={photoSource ? photoSource.uri : '/img/no-photo.svg'} style={user.main_photo.width > user.main_photo.height ? {width: 'auto', height: '100%'} : {width: '100%', height: 'auto'}} />
          : <img alt="" src={'/img/no-photo.svg'} style={{width: 'auto', height: '90%', top: 'auto', transform: 'translateX(-50%)'}} />}
        <div className="card_wrap">
          <div className="card_location">{locationText()}</div>
        </div>
        <div className="card_actions">
          {fromFavorites ? (
            <div
              className="btn_round btn_favorites active"
              onClick={handleRemoveFromFavorite}>
              <IconStart />
              <div className="remove_favorites">
                <IconClose />
              </div>
            </div>
          ) : null}
          {user.is_favorite || fromFavorites ? null : (
            <div
              className="btn_round btn_favorites"
              onClick={handleAddToFavorite}>
              <IconStart />
            </div>
          )}
          <Link
            to={{
              pathname: '/chat', state: {modal: true, user: user}
            }}
            className="btn_round btn_chat"
          >
            <MessagesActive />
          </Link>
        </div>
      </div>
      <div className="card_content">
        <Link className="card_link" to={{
          pathname: `/${user.id}`,
          state: {modal: true, source: 'catalog'},
        }}></Link>
        {renderName()}
        {renderAbout()}
      </div>
    </>
  );
};
