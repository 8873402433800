import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {ReactComponent as IconWhiteClose} from '../../Components/Icons/WhiteClose.svg';
import {bindActionCreators} from 'redux';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import * as bindLogoutActions from '../../store/actions/auth/logoutActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import {ReactComponent as IconEdit} from '../../Components/Icons/Edit.svg';
import {ReactComponent as IconArrowLeft} from '../../Components/Icons/ArrowLeft.svg';
import { ReactComponent as IconSettings } from '../../Components/Icons/Settings.svg';
import {ReactComponent as IconSupport} from '../../Components/Icons/Support.svg';
import {ReactComponent as IconUpgrade} from '../../Components/Icons/Upgrade.svg';
import {ReactComponent as IconCoins} from '../../Components/Icons/Coins.svg';
import {ReactComponent as IconProfileEdit} from '../../Components/Icons/ProfileEdit.svg';
import {ReactComponent as IconPhotoUpload} from '../../Components/Icons/PhotoUpload.svg';
import './style.scss';
import {LoadPhotoBtn} from '../../Components/UI/LoadPhotoBtn';
import * as bindUploadActions from '../../store/actions/uploadActions';
import {ReactComponent as IconWhiteDot} from '../../Components/Icons/WhiteDot.svg';
import {ConfirmDelete} from '../../Components/UI/ConfirmDelete';
import {languages} from '../../config/searchConfig';
import __ from '../../utils/translate';
import modalRouteService from '../../services/ModalRouteService';
import Carousel from '../../Components/Сarousel';

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      moreDescription: false,
      showActions: false,
      showConfirm: false,
      showConfirmLogout: false,
    };
  }

  confirmDeleteProfile = (value) => {
    this.setState({
      showConfirm: false,
    });
    if (value) {
      this.props.eventsActions.closeModal(false);
      this.goBack();
      this.props.myProfileActions.deleteAccount();
    }
  };

  confirmLogout = (value) => {
    this.setState({
      showConfirmLogout: false,
    });
    if (value) {
      // Без этого блюр иногда не уходит, хз
      setTimeout(() => this.props.eventsActions.closeModal(false), 1000);
      this.goBack();
      this.props.logoutActions.logout();
    }
  };

  showActions = () => {
    this.setState({
      showActions: !this.state.showActions,
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showActions: false,
      });
    }
  }

  moreDescription = () => {
    this.setState({
      moreDescription: true,
    });
  };

  fileSelectedHandler = (e) => {
    if (e.target.files.length) {
      const imageURI = URL.createObjectURL(e.target.files[0]);
      const image = e.target.files[0];
      this.setState(
        {
          imageURI,
          image,
        },
        () => {
          const photo = {
            uri: this.state.imageURI,
            image: this.state.image,
            albumType: 'Default',
            isDocument: true,
          };
          this.props.uploadActions
            .upload([photo])
            .then(() => {
              this.props.myProfileActions.loadMyProfile();
            })
            .catch(() => null);
        },
      );
    }
  };

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
    document.addEventListener('mousedown', this.handleClickOutside);
    this.props.myProfileActions.loadMyProfile();
    this.props.purchaseActions.loadProducts();
  }

  openEditProfile = () => {
    this.props.history.push('/edit-profile');
  };

  goBack() {
    this.props.eventsActions.closeModal(false);
    modalRouteService.removePath();
    if (modalRouteService.currentPath()) {
      this.props.history.replace({ pathname: modalRouteService.currentPath() });
    } else {
      if (this.props.location.state && this.props.location.state.initialRoute) {
        this.props.history.replace({ pathname: this.props.location.state.initialRoute });
      } else {
        this.props.history.replace({ pathname: '/' });
      }
      modalRouteService.removeAllStack();
    }
  }

  renderName = () => {
    const {name, age, coins} = this.props.myProfile;

    if (this.props.screen.isMobileMode) {
      return (
        <div className="profile_title">
          {name}, {age}
        </div>
      );
    }
    return (
      <div className="profile_title">
        {name}, {age} &nbsp;&nbsp;
        <div onClick={() => this.showAddCoins()} className="my-profile__coins">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17">
            <path fillRule="evenodd"
                  d="M7 0C3.134 0 0 1.234 0 2.757v1.319C0 5.691 3.134 7 7 7s7-1.31 7-2.924v-1.32C14 1.235 10.866 0 7 0m6.738 6.148C12.908 7.421 10.206 8.353 7 8.353c-3.205 0-5.908-.932-6.738-2.205C.092 5.886 0 6.028 0 6.147v2.486C0 10.493 3.134 12 7 12s7-1.507 7-3.367V6.147c0-.119-.091-.261-.262.001m-.012 5.012c-.843 1.336-3.535 2.314-6.726 2.314-3.19 0-5.883-.978-6.726-2.314-.179-.283-.274-.132-.274.009v1.961C0 15.03 3.134 17 7 17s7-1.97 7-3.87V11.17c0-.14-.095-.292-.274-.01"/>
          </svg>
          {' '}{coins}{' '}{__('Coins')}
        </div>
      </div>
    );
  };

  locationText = () => {
    const {location_city_text, location_country_text} = this.props.myProfile;
    if (
      !location_country_text &&
      !location_city_text &&
      !this.props.screen.isMobileMode
    ) {
      return (
        <div className="profile_location">
          <Link
            to={{
              pathname: '/edit-profile',
              state: {modal: true},
            }}
            className="row-action"
            onClick={this.openEditProfile}>
            <IconEdit />
            <span>{__('Add your location')}</span>
          </Link>
        </div>
      );
    }
    if (location_city_text && location_country_text) {
      if (this.props.screen.isMobileMode) {
        return (
          <p className="location_text">
            {location_city_text},
            <br />
            {location_country_text}
          </p>
        );
      }
      return (
        <p className="profile_location">
          <span>
            {location_city_text}, {location_country_text}
          </span>
        </p>
      );
    }
    if (location_city_text) {
      return location_city_text;
    }
    if (location_country_text) {
      return location_country_text;
    }
  };

  slider = (isBottomList) => {
    const isMobileMode = this.props.screen.isMobileMode;
    if (
      this.props.myProfile.albums &&
      !this.props.myProfile.albums.length &&
      !isBottomList
    ) {
      return (
        <>
          {isMobileMode ? (
            <>
              <div className="bg_up"></div>
              <div
                className="mobile_upload"
                onClick={() => this.fileInput.click()}>
                <div className="mobile_upload_circle">
                  <IconPhotoUpload />
                </div>
              </div>
              <div className="bg_down"></div>
            </>
          ) : (
            <div className="load-photo__wrap">
              <LoadPhotoBtn onClick={() => this.fileInput.click()} />
              <LoadPhotoBtn onClick={() => this.fileInput.click()} />
              <LoadPhotoBtn onClick={() => this.fileInput.click()} />
            </div>
          )}

          <input
            type="file"
            id="upload-button"
            style={{display: 'none'}}
            onChange={this.fileSelectedHandler}
            ref={(fileInput) => (this.fileInput = fileInput)}
          />
        </>
      );
    }
    if (this.props.myProfile.albums && this.props.myProfile.albums.length) {
      const {photos} = this.props.myProfile.albums[0];

      try {
        const parsedPhotos = Object.values(photos);
        return (
          <>
            {photos ? (
              <>
                <Carousel
                  loadPhoto={true}
                  isMyProfile={true}
                  onClick={() => this.fileInput.click()}
                  isBottomList={isBottomList}
                  isMobileMode={isMobileMode}
                  photos={parsedPhotos}
                />
              </>
            ) : null}
            {isBottomList ? (
              <>
                <div
                  className="mobile_upload mobile_upload_bottom"
                  onClick={() => this.fileInput.click()}>
                  <div className="mobile_upload_circle">
                    <IconPhotoUpload />
                  </div>
                </div>
              </>
            ) : null}
                <input
                  type="file"
                  id="upload-button"
                  style={{display: 'none'}}
                  onChange={this.fileSelectedHandler}
                  ref={(fileInput) => (this.fileInput = fileInput)}
                />
          </>
        );
      } catch (e) {
        return null;
      }
    }
  };

  renderAbout = () => {
    const {about} = this.props.myProfile;
    const clsDescription = ['profile_description', 'user_description'];
    if (this.state.moreDescription === true) {
      clsDescription.push('active');
    }
    if (!about) {
      return (
        <div
          className="profile_description user_description"
          style={{width: '100%'}}>
          <Link
            to={{
              pathname: '/edit-profile',
              state: {modal: true},
            }}
            className="row-action">
            <IconEdit />
            <span>{__('Write about yourself')}</span>
          </Link>
        </div>
      );
    }
    return (
      <p className={clsDescription.join(' ')} onClick={this.moreDescription}>
        <span>{about}</span>
      </p>
    );
  };

  renderAboutMobile = () => {
    const {about} = this.props.myProfile;
    if (!about) {
      return (
        <div className="profile_row">
          <p className="profile_row__title">{__('About you')}</p>
          <Link
            to={{
              pathname: '/edit-profile',
              state: {modal: true},
            }}
            className="row-action"
            onClick={this.openEditProfile}>
            <IconEdit />
            <span>{__('Write about yourself')}</span>
          </Link>
        </div>
      );
    }
    if (this.props.screen.isMobileMode) {
      return <p className="about_mobile">{about}</p>;
    }
  };

  renderActivities = () => {
    const {activities} = this.props.myProfile;

    try {
      const parsedActivities = JSON.parse(activities);

      if (parsedActivities && !parsedActivities.length) {
        return (
          <div className="profile_row">
            <p className="profile_row__title">{__('Activities:')}</p>
            <Link
              to={{
                pathname: '/edit-profile',
                state: {modal: true},
              }}
              className="row-action"
              onClick={this.openEditProfile}>
              <IconEdit />
              <span>{__('Choose your activities')}</span>
            </Link>
          </div>
        );
      }

      if (
        this.props.screen.isMobileMode &&
        parsedActivities &&
        parsedActivities.length
      ) {
        return (
          <div className="activity_wrap">
            <p className="activity_title">{__('Activities:')}</p>
            {parsedActivities.map((item, i) => (
              <p key={i} className="activity_item">
                {__(`${item}`)}
              </p>
            ))}
          </div>
        );
      }

      return (
        <>
          {parsedActivities && parsedActivities.length ? (
            <div className="profile_row">
              <p className="profile_row__title">{__('Activities:')}</p>
              <p className="profile_row__text">
                {parsedActivities.map((item, i) => {
                  return (
                    <span key={i}>
                      {__(`${item}`)}
                      {i === parsedActivities.length - 1 ? null : ', '}
                    </span>
                  );
                })}
              </p>
            </div>
          ) : null}
        </>
      );
    } catch (e) {
      return null;
    }
  };

  renderOccupation = () => {
    const {occupation} = this.props.myProfile;

    return occupation ? (
      <div className="profile_row">
        <p className="profile_row__title">{__('Works as')}:</p>
        <p className="profile_row__text">{occupation}</p>
      </div>
    ) : (
      <div className="profile_row">
        <p className="profile_row__title">{__('Works as')}:</p>
        <Link
          to={{
            pathname: '/edit-profile',
            state: {modal: true},
          }}
          className="row-action"
          onClick={this.openEditProfile}>
          <IconEdit />
          <span>{__('Add your occupation')}</span>
        </Link>
      </div>
    );
  };

  renderLanguages = () => {
    return (
      <div className="profile_row">
        <p className="profile_row__title">{__('Languages:')}</p>
        <p className="profile_row__text">{languages.filter((language) =>
          this.props.myProfile.speak_languages.includes(language.id),
        ).map((lang, i) => (
          <span key={i}>
            {lang.name}
            {i < languages.filter((language) => this.props.myProfile.speak_languages.includes(language.id)).length - 1 ? ', ' : null}
          </span>))}
        </p>
      </div>
    );
  };

  showAddCoins = () => {
    localStorage.setItem(
      'urlAddCoins',
      JSON.stringify({
        pathname: this.props.history.location.pathname,
        state: this.props.history.location.state,
      }),
    );
    this.props.history.push({
      pathname: '/add-coins',
      state: {modal: true},
    });
  };

  showGetPremium = () => {
    this.props.history.push({
      pathname: '/get-premium',
      state: {modal: true},
    });
  };

  render() {
    const {is_vip, isFetching} = this.props.myProfile;
    const isMobileMode = this.props.screen.isMobileMode;
    const {coins} = this.props.myProfile;
    return (
      <>
        {isFetching ? (
          <div className="loading_profile">{__('Loading...')}</div>
        ) : !isMobileMode ? (
          <div className="popup_body">
            <div className="popup_head">
              <div className="profile_info">
                {this.renderName()}
                {this.locationText()}
              </div>
              {
                is_vip
                ?
                  <div className="premium_indicator">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                      <g id="Crown">
                        <path id="Path" fill-rule="evenodd" clip-rule="evenodd" d="M17.8097 3.21943C17.6693 3.11693 17.4907 3.13093 17.362 3.25359L13.1632 7.26443L9.32891 0.213956C9.25639 0.0806537 9.13274 0 8.99978 0C8.86729 0 8.74363 0.0806537 8.67111 0.213956L4.83681 7.26443L0.638053 3.25359C0.509282 3.13093 0.330769 3.11693 0.190377 3.21943C0.0495193 3.32192 -0.0234662 3.51852 0.00675072 3.71287L1.68216 14.4628C1.7161 15.2643 2.49709 15.9039 4.00608 16.3649C5.34492 16.7743 7.11843 17 8.99978 17C10.8816 17 12.6551 16.7743 13.9939 16.3649C15.5029 15.9039 16.2839 15.2643 16.3179 14.4628L17.9933 3.71287C18.0235 3.51852 17.95 3.32192 17.8097 3.21943Z" fill="#F7B500"/>
                        <path id="Path_2" fill-rule="evenodd" clip-rule="evenodd" d="M13.4094 15.4473C12.236 15.8037 10.6702 16 8.99979 16C7.32979 16 5.76398 15.8037 4.59058 15.4473C3.29207 15.0527 3 14.6207 3 14.5C3 14.3799 3.29207 13.9473 4.59058 13.5527C5.76398 13.1963 7.32979 13 8.99979 13C10.6702 13 12.236 13.1963 13.4094 13.5527C14.7079 13.9473 15 14.3799 15 14.5C15 14.6207 14.7079 15.0527 13.4094 15.4473Z" fill="white"/>
                      </g>
                    </svg>
                    <span>Premium</span>
                  </div>
                  :null
              }
              <div className="btn_close" onClick={this.goBack}>
                <IconClose />
              </div>
            </div>
            <div className="popup_content scroll mobile-scroll flex-1">
              <div className="profile_gallery_wrap">{this.slider()}</div>
              <div className="my-profile__info">
                {this.renderAbout()}
                <div className="profile_list">
                  {this.renderOccupation()}
                  {this.renderLanguages()}
                  {this.renderActivities()}
                </div>
              </div>
              <div className="collection settings_collection mobile-pt-100">
                {/*<Link*/}
                {/*  to={{*/}
                {/*    pathname: '/get-premium',*/}
                {/*    state: { modal: true },*/}
                {/*  }}*/}
                {/*  className="collection_row"*/}
                {/*>*/}
                {/*  <div className="collection_col collection_col_left">*/}
                {/*    <IconSettings/>*/}
                {/*    <span className="collection_text">{__('Get Premium')}</span>*/}
                {/*  </div>*/}
                {/*  <span className="collection_col collection_col_arrow">*/}
                {/*    <IconArrowLeft/>*/}
                {/*  </span>*/}
                {/*</Link>*/}

                <Link
                  to={{
                    pathname: '/edit-profile',
                    state: {modal: true},
                  }}
                  className="collection_row"
                  onClick={this.openEditProfile}>
                  <div className="collection_col collection_col_left">
                    <IconEdit />
                    <span className="collection_text">
                      {__('Edit profile')}
                    </span>
                  </div>
                  <span className="collection_col collection_col_arrow">
                    <IconArrowLeft />
                  </span>
                </Link>
                <Link
                  className="collection_row"
                  to={{
                    pathname: '/my-profile-settings',
                    state: {modal: true},
                  }}
                >
                  <div className="collection_col collection_col_left">
                    <IconSettings />
                    <span className="collection_text">{__('Settings')}</span>
                  </div>
                  <span className="collection_col collection_col_arrow">
                    <IconArrowLeft />
                  </span>
                </Link>

                <a
                  href="mailto:support@corp.loka.world"
                  className="collection_row">
                  <div className="collection_col collection_col_left">
                    <IconSupport />
                    <span className="collection_text">{__('Support')}</span>
                  </div>
                  <span className="collection_col collection_col_arrow">
                    <IconArrowLeft />
                  </span>
                </a>
              </div>
            </div>

            <div className="popup_footer my-profile__footer">
              <div className="my-profile__actions">
                {is_vip ? (
                  <div
                    onClick={() => this.showAddCoins()}
                    className="btn btn_favorites">
                    <IconCoins />
                    <span>{__('Add Coins')}</span>
                  </div>
                ) : (
                  <div
                    className="btn btn_primary"
                    onClick={() => this.showGetPremium()}>
                    <IconUpgrade />
                    <span>{__('Upgrade now')}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="popup_body popup_body__profile">
            <div className="popup_content scroll flex-1">
              <div className="profile_gallery_wrap">
                <div
                  className="btn_close mobile_btn_close"
                  onClick={this.goBack}>
                  <IconWhiteClose />
                </div>
                {/*show premium || coins for mobile*/}
                {
                  is_vip
                    ?
                    <div className="premium_coins_wrapper">
                      <div className="premium_indicator">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                          <g id="Crown">
                            <path id="Path" fill-rule="evenodd" clip-rule="evenodd" d="M17.8097 3.21943C17.6693 3.11693 17.4907 3.13093 17.362 3.25359L13.1632 7.26443L9.32891 0.213956C9.25639 0.0806537 9.13274 0 8.99978 0C8.86729 0 8.74363 0.0806537 8.67111 0.213956L4.83681 7.26443L0.638053 3.25359C0.509282 3.13093 0.330769 3.11693 0.190377 3.21943C0.0495193 3.32192 -0.0234662 3.51852 0.00675072 3.71287L1.68216 14.4628C1.7161 15.2643 2.49709 15.9039 4.00608 16.3649C5.34492 16.7743 7.11843 17 8.99978 17C10.8816 17 12.6551 16.7743 13.9939 16.3649C15.5029 15.9039 16.2839 15.2643 16.3179 14.4628L17.9933 3.71287C18.0235 3.51852 17.95 3.32192 17.8097 3.21943Z" fill="#F7B500"/>
                            <path id="Path_2" fill-rule="evenodd" clip-rule="evenodd" d="M13.4094 15.4473C12.236 15.8037 10.6702 16 8.99979 16C7.32979 16 5.76398 15.8037 4.59058 15.4473C3.29207 15.0527 3 14.6207 3 14.5C3 14.3799 3.29207 13.9473 4.59058 13.5527C5.76398 13.1963 7.32979 13 8.99979 13C10.6702 13 12.236 13.1963 13.4094 13.5527C14.7079 13.9473 15 14.3799 15 14.5C15 14.6207 14.7079 15.0527 13.4094 15.4473Z" fill="white"/>
                          </g>
                        </svg>
                        <span>Premium</span>
                      </div>
                      <div onClick={() => this.showAddCoins()} className="my-profile__coins">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="#fff">
                          <path fillRule="evenodd"
                                d="M7 0C3.134 0 0 1.234 0 2.757v1.319C0 5.691 3.134 7 7 7s7-1.31 7-2.924v-1.32C14 1.235 10.866 0 7 0m6.738 6.148C12.908 7.421 10.206 8.353 7 8.353c-3.205 0-5.908-.932-6.738-2.205C.092 5.886 0 6.028 0 6.147v2.486C0 10.493 3.134 12 7 12s7-1.507 7-3.367V6.147c0-.119-.091-.261-.262.001m-.012 5.012c-.843 1.336-3.535 2.314-6.726 2.314-3.19 0-5.883-.978-6.726-2.314-.179-.283-.274-.132-.274.009v1.961C0 15.03 3.134 17 7 17s7-1.97 7-3.87V11.17c0-.14-.095-.292-.274-.01"/>
                        </svg>
                        {' '}{coins}{' '}{__('Coins')}
                      </div>
                    </div>
                    : <div onClick={() => this.showAddCoins()} className="my-profile__coins">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="#fff">
                        <path fillRule="evenodd"
                              d="M7 0C3.134 0 0 1.234 0 2.757v1.319C0 5.691 3.134 7 7 7s7-1.31 7-2.924v-1.32C14 1.235 10.866 0 7 0m6.738 6.148C12.908 7.421 10.206 8.353 7 8.353c-3.205 0-5.908-.932-6.738-2.205C.092 5.886 0 6.028 0 6.147v2.486C0 10.493 3.134 12 7 12s7-1.507 7-3.367V6.147c0-.119-.091-.261-.262.001m-.012 5.012c-.843 1.336-3.535 2.314-6.726 2.314-3.19 0-5.883-.978-6.726-2.314-.179-.283-.274-.132-.274.009v1.961C0 15.03 3.134 17 7 17s7-1.97 7-3.87V11.17c0-.14-.095-.292-.274-.01"/>
                      </svg>
                      {' '}{coins}{' '}{__('Coins')}
                    </div>
                }
                {this.slider()}
                <div className="location_text_wrap">{this.locationText()}</div>
                <div className="report_section">
                  <span className="btn_more" onClick={() => this.showActions()}>
                    <IconWhiteDot />
                  </span>
                </div>
                {this.state.showActions ? (
                  <div
                    className="my-profile__actions-menu"
                    ref={this.setWrapperRef}>
                    <p
                      className="report_block__item footer__link"
                      onClick={() => {
                        this.props.eventsActions.closeModal(false);
                        this.props.history.push('/terms');
                      }}>
                      {__('Terms of Use')}
                    </p>
                    <p
                      className="report_block__item footer__link"
                      onClick={() => {
                        this.props.eventsActions.closeModal(false);
                        this.props.history.push('/policy');
                      }}>
                      {__('Privacy Policy')}
                    </p>
                    <p
                      className="report_block__item report_block__item-action"
                      onClick={() => {
                        this.setState({showConfirm: true});
                      }}>
                      {__('Delete profile')}
                    </p>
                    <p
                      className="report_block__item report_block__item-action"
                      onClick={() => {
                        this.setState({showConfirmLogout: true});
                      }}>
                      {this.props.screen.isMobileMode ? (
                        __('Log out')
                      ) : (
                        <strong>{__('Log out')}</strong>
                      )}
                    </p>
                    <p
                      className="report_block__item"
                      onClick={() => this.showActions()}>
                      {__('Cancel')}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="profile_wrapper">
                {this.renderName()}
                {this.renderOccupation()}
                {this.renderAboutMobile()}
                {this.renderLanguages()}
                {this.renderActivities()}
                {this.slider(true)}
                <div className="mobile_profile_footer_height"></div>
              </div>
            </div>
            <div className="mobile_profile_footer">
              {is_vip ? (
                <div
                  onClick={() => this.showAddCoins()}
                  className="btn btn_favorites mobile_profile_footer_btns">
                  <span>{__('Add Coins')}</span>
                </div>
              ) : (
                <div
                  className="btn btn_primary mobile_profile_footer_btns"
                  onClick={() => this.showGetPremium()}>
                  <span>{__('Upgrade now')}</span>
                </div>
              )}
              <div
                onClick={() => this.openEditProfile()}
                className="btn btn_favorites mobile_btn_favorites"
                style={{backgroundColor: '#9013fe'}}>
                <div className="btn_icon">
                  <IconProfileEdit />
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.showConfirm ? (
          <ConfirmDelete
            textLine2={__('This can’t be undone.')}
            textLine1={__('You will lose all your contacts.')}
            textButton={__('Delete my profile')}
            title={__('Are you sure?')}
            confirm={(value) => this.confirmDeleteProfile(value)}
          />
        ) : null}
        {this.state.showConfirmLogout ? (
          <ConfirmDelete
            textLine1={__(
              'You will not recieve notifications about new messages.',
            )}
            textLine2=""
            textButton={__('Logout')}
            title={__('Are you sure you want to log out?')}
            confirm={(value) => this.confirmLogout(value)}
          />
        ) : null}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      myProfile: state.myProfile,
      upload: state.upload,
      screen: state.screen,
    }),
    (dispatch) => ({
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
      purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      uploadActions: bindActionCreators(bindUploadActions, dispatch),
      logoutActions: bindActionCreators(bindLogoutActions, dispatch),
    }),
  )(MyProfile),
);
