import React, {Component} from 'react';
import AuthMenu from '../../Components/Navigation/AuthMenu';
import {connect} from 'react-redux';

class AuthLayout extends Component {
  render() {
    const isMobileMode = this.props.screen.isMobileMode;
    return (
      <div
        className="layout-auth"
        style={
          this.props.isRegistrationPages && isMobileMode
            ? {backgroundColor: 'white'}
            : {}
        }>
        <AuthMenu isRegistrationPages={this.props.isRegistrationPages} />
        {this.props.children}
      </div>
    );
  }
}

export default connect((state) => ({
  screen: state.screen,
}))(AuthLayout);
