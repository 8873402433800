import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {ReactComponent as FiveCoins} from '../../Components/Icons/FiveCoins.svg';
import {ReactComponent as ThreeCoins} from '../../Components/Icons/ThreeCoins.svg';
import {ReactComponent as TwoCoins} from '../../Components/Icons/TwoCoins.svg';
import {ReactComponent as OneCoin} from '../../Components/Icons/OneCoin.svg';
import {bindActionCreators} from 'redux';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import __ from '../../utils/translate';
import './styles.scss';
import {PAYMENT_TYPE} from '../../store/constants';
import modalRouteService from '../../services/ModalRouteService';

// client_id = ATCOkjLVQ0C18j1ZLQqevzVR3EcWbZojzPg3q-OPYSgPGfUXruyQTti40yldSvhLAUwIs7IDpimGUcoD
// secret    = EK11uVIh42wK00ahYL7jbT8qbnVpTd7HsbberxUseOLdcHkyk64XYPYu1ryYbtj45YLBHDggCq1BYLTp

class AddCoins extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.state = {};
    }

    handleOnClick = () => {
        this.props.eventsActions.closeModal(false);
    };

    componentDidMount() {
        modalRouteService.addPath(this.props.match.url);
    }

    goBack() {
        this.props.eventsActions.closeModal(false);
        modalRouteService.removePath();
        this.props.history.replace({pathname: modalRouteService.currentPath()});
        if (modalRouteService.currentPath()) {
            if (modalRouteService.currentPath() === '/chat') {
                this.props.history.replace({pathname: '/messages'});
            } else {
                this.props.history.replace({pathname: modalRouteService.currentPath()});
            }
        } else {
            if (this.props.location.state && this.props.location.state.initialRoute) {
                this.props.history.replace({pathname: this.props.location.state.initialRoute});
            } else {
                this.props.history.replace({pathname: '/'});
            }
            modalRouteService.removeAllStack();
        }
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.goBack();
        }
    }

    choosePayment(productAmount, productId, productCurrency) {
        if (this.props.credentials && this.props.credentials.user_id === 136810) {
            this.props.purchaseActions.getPaymentUrl(productId);
            this.props.history.push({
                pathname: '/get-payment-coins',
                state: {modal: true, productId},
            });

            return
        }

        switch (PAYMENT_TYPE) {
            case 'pw': {
                this.props.purchaseActions.getPaymentUrl(productId);
                this.props.history.push({
                    pathname: '/get-payment-coins',
                    state: {modal: true, productId},
                });
                break
            }
            case 'paypal': {
                this.props.history.push({
                    pathname: '/get-payment-coins',
                    state: {modal: true, productAmount, productId, productCurrency, go: 3},
                });
                break
            }
            case 'stripe': {
                this.props.purchaseActions.stripeGetSession(productId)
                break
            }
            default: {
                this.props.purchaseActions.getPaymentUrl(productId);
                this.props.history.push({
                    pathname: '/modal/get-payment-coins',
                    state: {modal: true, productId},
                });
            }
        }
    }

    render() {
        const {isFetching, tariffs} = this.props.purchase;
        return (
            <div className="popup_body popup-purchase">
                <div className="popup-purchase__head">
                    <div className="btn_close" onClick={this.goBack}>
                        <IconClose/>
                    </div>
                    <h1 className="popup-purchase__title">{__('Add Coins')}</h1>
                    <p className="popup-purchase__description">{__('The more you buy the more you save.')}</p>
                </div>
                <div className="popup-purchase__content popup_content scroll">
                    {
                        isFetching ? __('Loading...') : (
                            tariffs.coins && tariffs.coins.length ? (
                                tariffs.coins.sort(function (a, b) {
                                    return b.amount - a.amount;
                                }).map((product, i) => (
                                    <div key={i} className="purchase-coins"
                                         onClick={() => this.choosePayment(product.amount, product.id, product.currency.toUpperCase())}
                                    >
                                        <div>
                                            <div className="purchase-coins__coins">
                                                {i === 0 ? <FiveCoins/> : null}
                                                {i === 1 ? <ThreeCoins/> : null}
                                                {i === 2 ? <TwoCoins/> : null}
                                                {i === 3 ? <OneCoin/> : null}
                                                {product.value}
                                            </div>
                                            <div
                                                className="purchase-coins__sum">{product.currency === 'eur' ? '€' : '$'}{product.amount}</div>
                                        </div>
                                        {i === 0 ? <div className="purchase-coins__discount">
                                            <p>{product.currency === 'eur' ? '€' : '$'}0.06 /{__('Coin')}</p>
                                            <p>Save 60%</p>
                                        </div> : null}
                                        {i === 1 ? <div className="purchase-coins__discount">
                                            <p>{product.currency === 'eur' ? '€' : '$'}0.07 /{__('Coin')}</p>
                                            <p>Save 50%</p>
                                        </div> : null}
                                        {i === 2 ? <div className="purchase-coins__discount">
                                            <p>{product.currency === 'eur' ? '€' : '$'}0.10 /{__('Coin')}</p>
                                            <p>Save 33%</p>
                                        </div> : null}
                                        {i === 3 ? <div className="purchase-coins__discount">
                                            <p>{product.currency === 'eur' ? '€' : '$'}0.15 /{__('Coin')}</p>
                                        </div> : null}
                                    </div>
                                ))
                            ) : 'error'
                        )
                    }
                </div>
                <div className="popup-purchase__agree">
                    <Link to="/terms" onClick={() => this.handleOnClick()}>
                        {__(
                            'Terms of Service {{and}} Privacy Policy',
                            {
                                and: (
                                    <span>{__('and')}</span>
                                ),
                            },
                        )}
                    </Link>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(
    (state) => ({
        purchase: state.purchase,
        credentials: state.credentials,
    }),
    (dispatch) => ({
        purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
        myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
        eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
)(AddCoins));
