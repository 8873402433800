import {
  PEOPLE_REQUEST,
  PEOPLE_SUCCESS,
  PEOPLE_FAIL,
  PEOPLE_LOAD_MORE_REQUEST,
  PEOPLE_LOAD_MORE_SUCCESS,
  PEOPLE_LOAD_MORE_FAIL,
  PEOPLE_SET_SEARCH_PARAMS,
  FAVORITES_ADD_SUCCESS,
} from '../constants';

const initialState = {
  users: [],
  isFetching: false,
  hasMore: false,
  page: 1,
  isFetchingMore: false,
  searchParams: null,
};

const peopleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITES_ADD_SUCCESS:
      const {user} = action.payload;
      return {
        ...state,
        users: state.users.map((item) => {
          if (item.user_id === user.user_id) {
            item.folder_type = user.folder_type;
            item.is_favorite = user.is_favorite;
          }
          return item;
        }),
      };
    case PEOPLE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case PEOPLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: action.payload.users,
        // hasMore: action.payload.show_more_link,
        hasMore: !!action.payload.users.length ? true : false, // todo: временное решение
        page: 2,
      };
    case PEOPLE_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case PEOPLE_LOAD_MORE_REQUEST:
      return {
        ...state,
        isFetchingMore: true,
      };
    case PEOPLE_LOAD_MORE_SUCCESS:
      return {
        ...state,
        users: [...state.users, ...action.payload.users],
        // hasMore: action.payload.show_more_link,
        hasMore: !!action.payload.users.length ? true : false, // todo: временное решение
        isFetchingMore: false,
        page: state.page + 1,
      };
    case PEOPLE_LOAD_MORE_FAIL:
      return {
        ...state,
        isFetchingMore: false,
        page: state.page - 1,
      };
    case PEOPLE_SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload,
      };
    default:
      return state;
  }
};

export default peopleReducer;
