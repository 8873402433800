let redirectURL = 'https://api.loka.world/v3';
if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    redirectURL = 'https://api.loka.staging.loka.work/v3';
}
export const API_URL = redirectURL;
export const PAYMENT_TYPE = 'stripe'; // paypal || pw || stripe
export const STORAGE_UNIQ_KEY = '$LOKA:KEY:QABPSBZYAZNCFVKIWAJYE';
export const CONFIG_APP_VERSION = 2;
export const APP_NAME = 'loka';

export const SYSTEM_USER_ID = 1;

export const MIN_TABLET_WIDTH = 480;

export const MAX_LOGIN_ATTEMPTS = 2;

// Social networks
export const VK_PROVIDER_CLIENT_ID = 0;
export const MAMBA_PROVIDER_CLIENT_ID = 0;
export const GOOGLE_PROVIDER_CLIENT_ID = '';
export const INSTAGRAM_PROVIDER_CLIENT_ID = '';

export const PROVIDER_VK = 'VK';
export const PROVIDER_FB = 'FB';
export const PROVIDER_MAMBA = 'MAMBA';
export const PROVIDER_INSTAGRAM = 'INSTAGRAM';
export const PROVIDER_FLICKR = 'FLICKR';
export const PROVIDER_GALLERY = 'GALLERY';
export const PROVIDER_CAMERA = 'CAMERA';

export const PHOTO_MIN_WIDTH = 360;
export const PHOTO_MIN_HEIGHT = 360;
export const PHOTO_MAX_WIDTH = 4000;
export const PHOTO_MAX_HEIGHT = 4000;

export const PHOTO_RESIZE_WIDTH = 900;
export const PHOTO_RESIZE_HEIGHT = 675;

export const GEOLOCATION_SUCCESS = 'GEOLOCATION_SUCCESS';
export const HIDE_INSTALL_APP = 'HIDE_INSTALL_APP';

export const BILLING_REQUEST = 'BILLING_REQUEST';
export const BILLING_SUCCESS = 'BILLING_SUCCESS';
export const BILLING_FAIL = 'BILLING_FAIL';

export const BILLING_STORE_REQUEST = 'BILLING_STORE_REQUEST';
export const BILLING_STORE_SUCCESS = 'BILLING_STORE_SUCCESS';
export const BILLING_STORE_FAIL = 'BILLING_STORE_FAIL';

export const BILLING_ORDER_REQUEST = 'BILLING_ORDER_REQUEST';
export const BILLING_ORDER_SUCCESS = 'BILLING_ORDER_SUCCESS';
export const BILLING_ORDER_FAIL = 'BILLING_ORDER_FAIL';

export const BILLING_RECEIPT_REQUEST = 'BILLING_RECEIPT_REQUEST';
export const BILLING_RECEIPT_FAIL = 'BILLING_RECEIPT_FAIL';
export const BILLING_RECEIPT_SUCCESS = 'BILLING_RECEIPT_SUCCESS';

export const BILLING_SEND_RECEIPT_REQUEST = 'BILLING_SEND_RECEIPT_REQUEST';
export const BILLING_SEND_RECEIPT_FAIL = 'BILLING_SEND_RECEIPT_FAIL';
export const BILLING_SEND_RECEIPT_SUCCESS = 'BILLING_SEND_RECEIPT_SUCCESS';

export const BILLING_UNSUBSCRIBE_REQUEST = 'BILLING_UNSUBSCRIBE_REQUEST';
export const BILLING_UNSUBSCRIBE_FAIL = 'BILLING_UNSUBSCRIBE_FAIL';
export const BILLING_UNSUBSCRIBE_SUCCESS = 'BILLING_UNSUBSCRIBE_SUCCESS';

export const BILLING_PURCHASE_REQUEST = 'BILLING_PURCHASE_REQUEST';
export const BILLING_PURCHASE_SUCCESS = 'BILLING_PURCHASE_SUCCESS';
export const BILLING_PURCHASE_FAIL = 'BILLING_PURCHASE_FAIL';

export const BILLING_WECHAT_URL_REQUEST = 'BILLING_WECHAT_URL_REQUEST';
export const BILLING_WECHAT_URL_SUCCESS = 'BILLING_WECHAT_URL_SUCCESS';

export const CREDIT_CARD_REQUEST = 'CREDIT_CARD_REQUEST';
export const CREDIT_CARD_SUCCESS = 'CREDIT_CARD_SUCCESS';
export const CREDIT_CARD_FAIL = 'CREDIT_CARD_FAIL';

export const RESET_SESSION_STATE = 'RESET_SESSION_STATE';

export const SKIP_APP_RATE = 'SKIP_APP_RATE';

export const CONFIG_SUCCESS = 'CONFIG_SUCCESS';

export const CURRENT_LANGUAGE = 'CURRENT_LANGUAGE';

export const COUNTERS_FAIL = 'COUNTERS_FAIL';
export const COUNTERS_REQUEST = 'COUNTERS_REQUEST';
export const COUNTERS_SUCCESS = 'COUNTERS_SUCCESS';

export const COUNTERS_EVENTS_RECEIVED = 'COUNTERS_EVENTS_RECEIVED';
export const COUNTERS_MESSAGES_RECEIVED = 'COUNTERS_MESSAGES_RECEIVED';

export const DEVICE_INFO_SUCCESS = 'DEVICE_INFO_SUCCESS';

export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const AUTO_LOGIN_FAIL = 'AUTO_LOGIN_FAIL';
export const AUTO_LOGIN_REQUEST = 'AUTO_LOGIN_REQUEST';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const LOGIN_ERRORS_CLEAR = 'LOGIN_ERRORS_CLEAR';
export const SOCIAL_LOGIN_SUCCESS = 'SOCIAL_LOGIN_SUCCESS';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const REGISTRATION_STARTS = 'REGISTRATION_STARTS';
export const REGISTRATION_DONE = 'REGISTRATION_DONE';
export const REGISTRATION_SET_STEP = 'REGISTRATION_SET_STEP';
export const REGISTRATION_SET_STEPS = 'REGISTRATION_SET_STEPS';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';
export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_ERRORS_CLEAR = 'REGISTRATION_ERRORS_CLEAR';
// own registration types
export const REGISTRATION_SET_EMAIL_SUCCESS = 'REGISTRATION_SET_EMAIL_SUCCESS';
export const REGISTRATION_SET_EMAIL_FAIL = 'REGISTRATION_SET_EMAIL_FAIL';
export const REGISTRATION_REQUEST_CHECK_EMAIL =
    'REGISTRATION_REQUEST_CHECK_EMAIL';

export const LOCATION_BY_IP_SUCCESS = 'LOCATION_BY_IP_SUCCESS';

export const MARKETING_APPS_FLYER_SUCCESS = 'MARKETING_APPS_FLYER_SUCCESS';

export const MARKETING_BRANCH_SUCCESS = 'MARKETING_BRANCH_SUCCESS';

export const RESTORE_PASSWORD_FAIL = 'RESTORE_PASSWORD_FAIL';
export const RESTORE_PASSWORD_REQUEST = 'RESTORE_PASSWORD_REQUEST';
export const RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';

export const RESTORE_PASSWORD_CHANGE_FAIL = 'RESTORE_PASSWORD_CHANGE_FAIL';
export const RESTORE_PASSWORD_CHANGE_REQUEST =
    'RESTORE_PASSWORD_CHANGE_REQUEST';
export const RESTORE_PASSWORD_CHANGE_SUCCESS =
    'RESTORE_PASSWORD_CHANGE_SUCCESS';
export const RESTORE_PASSWORD_ERRORS_CLEAR = 'RESTORE_PASSWORD_ERRORS_CLEAR';

export const CHECK_GUARD_CODE_FAIL = 'CHECK_GUARD_CODE_FAIL';
export const CHECK_GUARD_CODE_REQUEST = 'CHECK_GUARD_CODE_REQUEST';
export const CHECK_GUARD_CODE_SUCCESS = 'CHECK_GUARD_CODE_SUCCESS';
export const CHECK_GUARD_CODE_ERRORS_CLEAR = 'CHECK_GUARD_CODE_ERRORS_CLEAR';

export const LOCATION_REQUEST = 'LOCATION_REQUEST';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const LOCATION_FAIL = 'LOCATION_FAIL';
export const LOCATION_QUERY_FAIL = 'LOCATION_QUERY_FAIL';
export const LOCATION_RESET = 'LOCATION_RESET';
export const LOCATION_DEFAULT_SUCCESS = 'LOCATION_DEFAULT_SUCCESS';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';
export const PROFILE_RESET = 'PROFILE_RESET';

export const PROFILE_NEXT_REQUEST = 'PROFILE_NEXT_REQUEST';
export const PROFILE_NEXT_SUCCESS = 'PROFILE_NEXT_SUCCESS';
export const PROFILE_NEXT_FAIL = 'PROFILE_NEXT_FAIL';

export const PROFILE_PREVIOUS_SUCCESS = 'PROFILE_PREVIOUS_SUCCESS';

export const PROFILE_MEET_SUCCESS = 'PROFILE_MEET_SUCCESS';

export const PROFILE_REQUEST_ACCESS_SUCCESS = 'PROFILE_REQUEST_ACCESS_SUCCESS';

export const MY_PROFILE_REQUEST = 'MY_PROFILE_REQUEST';
export const MY_PROFILE_SUCCESS = 'MY_PROFILE_SUCCESS';
export const MY_PROFILE_FAIL = 'MY_PROFILE_FAIL';

export const MY_PROFILE_SAVE_REQUEST = 'MY_PROFILE_SAVE_REQUEST';
export const MY_PROFILE_SAVE_SUCCESS = 'MY_PROFILE_SAVE_SUCCESS';
export const MY_PROFILE_SAVE_FAIL = 'MY_PROFILE_SAVE_FAIL';

export const MY_PROFILE_SAVE_ERRORS_CLEAR = 'MY_PROFILE_SAVE_ERRORS_CLEAR';
export const MY_PROFILE_RESET_UPLOADE_FLAG = 'MY_PROFILE_RESET_UPLOADE_FLAG';

export const PROFILE_BLOCK_REQUEST = 'PROFILE_BLOCK_REQUEST';
export const PROFILE_BLOCK_SUCCESS = 'PROFILE_BLOCK_SUCCESS';
export const PROFILE_BLOCK_FAIL = 'PROFILE_BLOCK_FAIL';

export const PHOTO_MAKE_MAIN_REQUEST = 'PHOTO_MAKE_MAIN_REQUEST';
export const PHOTO_MAKE_MAIN_SUCCESS = 'PHOTO_MAKE_MAIN_SUCCESS';
export const PHOTO_MAKE_MAIN_FAIL = 'PHOTO_MAKE_MAIN_FAIL';

export const PHOTO_DELETE_REQUEST = 'PHOTO_DELETE_REQUEST';
export const PHOTO_DELETE_SUCCESS = 'PHOTO_DELETE_SUCCESS';
export const PHOTO_DELETE_FAIL = 'PHOTO_DELETE_FAIL';

export const LOCALS_REQUEST = 'LOCALS_REQUEST';
export const LOCALS_SUCCESS = 'LOCALS_SUCCESS';
export const LOCALS_FAIL = 'LOCALS_FAIL';

export const LOCALS_NEW_REQUEST = 'LOCALS_NEW_REQUEST';
export const LOCALS_NEW_SUCCESS = 'LOCALS_NEW_SUCCESS';
export const LOCALS_NEW_FAIL = 'LOCALS_NEW_FAIL';

export const LOCALS_UPDATE_SEARCH_PARAMS = 'LOCALS_UPDATE_SEARCH_PARAMS';
export const TRAVELERS_UPDATE_SEARCH_PARAMS = 'TRAVELERS_UPDATE_SEARCH_PARAMS';

export const UPDATE_COMET_CONNECTION_TIME = 'UPDATE_COMET_CONNECTION_TIME';

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAIL = 'SETTINGS_FAIL';

export const SETTINGS_SAVE_REQUEST = 'SETTINGS_SAVE_REQUEST';
export const SETTINGS_SAVE_SUCCESS = 'SETTINGS_SAVE_SUCCESS';
export const SETTINGS_SAVE_FAIL = 'SETTINGS_SAVE_FAIL';

export const SETTINGS_PHONE_VERIFY_REQUEST = 'SETTINGS_PHONE_VERIFY_REQUEST';
export const SETTINGS_PHONE_VERIFY_FAIL = 'SETTINGS_PHONE_VERIFY_FAIL';
export const SETTINGS_PHONE_VERIFY_SUCCESS = 'SETTINGS_PHONE_VERIFY_SUCCESS';
export const SETTINGS_PHONE_TIMEOUT_END = 'SETTINGS_PHONE_TIMEOUT_END';

export const SETTINGS_PHONE_CHECK_REQUEST = 'SETTINGS_PHONE_CHECK_REQUEST';
export const SETTINGS_PHONE_CHECK_SUCCESS = 'SETTINGS_PHONE_CHECK_SUCCESS';
export const SETTINGS_PHONE_CHECK_FAIL = 'SETTINGS_PHONE_CHECK_FAIL';

export const SETTINGS_CHANGE_EMAIL_REQUEST = 'SETTINGS_CHANGE_EMAIL_REQUEST';
export const SETTINGS_CHANGE_EMAIL_SUCCESS = 'SETTINGS_CHANGE_EMAIL_SUCCESS';
export const SETTINGS_CHANGE_EMAIL_FAIL = 'SETTINGS_CHANGE_EMAIL_FAIL';
export const SETTINGS_CHANGE_EMAIL_CONFIRM = 'SETTINGS_CHANGE_EMAIL_CONFIRM';

export const SETTINGS_CHANGE_PASSWORD_REQUEST =
    'SETTINGS_CHANGE_PASSWORD_REQUEST';
export const SETTINGS_CHANGE_PASSWORD_SUCCESS =
    'SETTINGS_CHANGE_PASSWORD_SUCCESS';
export const SETTINGS_CHANGE_PASSWORD_FAIL = 'SETTINGS_CHANGE_PASSWORD_FAIL';

export const SETTINGS_DOWNLOAD_INFORMATION_SUCCESS =
    'SETTINGS_DOWNLOAD_INFORMATION_SUCCESS';

export const SETTINGS_ACCOUNT_DELETE_REQUEST =
    'SETTINGS_ACCOUNT_DELETE_REQUEST';
export const SETTINGS_ACCOUNT_DELETE_SUCCESS =
    'SETTINGS_ACCOUNT_DELETE_SUCCESS';
export const SETTINGS_ACCOUNT_DELETE_FAIL = 'SETTINGS_ACCOUNT_DELETE_FAIL';

export const SETTINGS_ERRORS_CLEAR = 'SETTINGS_ERRORS_CLEAR';

export const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST';
export const UPLOAD_PHOTO_PROGRESS = 'UPLOAD_PHOTO_PROGRESS';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAIL = 'UPLOAD_PHOTO_FAIL';

export const TRAVELERS_REQUEST = 'TRAVELERS_REQUEST';
export const TRAVELERS_SUCCESS = 'TRAVELERS_SUCCESS';
export const TRAVELERS_FAIL = 'TRAVELERS_FAIL';

export const TRAVELERS_NEW_REQUEST = 'TRAVELERS_NEW_REQUEST';
export const TRAVELERS_NEW_SUCCESS = 'TRAVELERS_NEW_SUCCESS';
export const TRAVELERS_NEW_FAIL = 'TRAVELERS_NEW_FAIL';

export const TRIP_SAVE_REQUEST = 'TRIP_SAVE_REQUEST';
export const TRIP_SAVE_SUCCESS = 'TRIP_SAVE_SUCCESS';
export const TRIP_SAVE_FAIL = 'TRIP_SAVE_FAIL';

export const TRIP_DELETE_REQUEST = 'TRIP_DELETE_REQUEST';
export const TRIP_DELETE_SUCCESS = 'TRIP_DELETE_SUCCESS';
export const TRIP_DELETE_FAIL = 'TRIP_DELETE_FAIL';

export const PHOTO_LIKE_REQUEST = 'PHOTO_LIKE_REQUEST';
export const PHOTO_LIKE_SUCCESS = 'PHOTO_LIKE_SUCCESS';
export const PHOTO_LIKE_FAIL = 'PHOTO_LIKE_FAIL';

export const PHOTO_INFO_SUCCESS = 'PHOTO_INFO_SUCCESS';

export const COMMENTS_REQUEST = 'COMMENTS_REQUEST';
export const COMMENTS_SUCCESS = 'COMMENTS_SUCCESS';
export const COMMENTS_FAIL = 'COMMENTS_FAIL';

export const COMMENTS_HISTORY_REQUEST = 'COMMENTS_HISTORY_REQUEST';
export const COMMENTS_HISTORY_SUCCESS = 'COMMENTS_HISTORY_SUCCESS';
export const COMMENTS_HISTORY_FAIL = 'COMMENTS_HISTORY_FAIL';

export const COMMENTS_SEND_SUCCESS = 'COMMENTS_SEND_SUCCESS';
export const COMMENTS_REMOVE_SUCCESS = 'COMMENTS_REMOVE_SUCCESS';
export const COMMENTS_RESET_STATE = 'COMMENTS_RESET_STATE';

export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_HIDE = 'NOTIFICATION_HIDE';

export const EVENTS_REQUEST = 'EVENTS_REQUEST';
export const EVENTS_SUCCESS = 'EVENTS_SUCCESS';
export const EVENTS_FAIL = 'EVENTS_FAIL';

export const EVENTS_REQUEST_ACCEPT_SUCCESS = 'EVENTS_REQUEST_ACCEPT_SUCCESS';

export const EVENTS_OPEN_MODAL = 'EVENTS_OPEN_MODAL';

export const EVENTS_CLOSE_MODAL = 'EVENTS_CLOSE_MODAL';

export const RESET_EVENTS_COUNT = 'RESET_EVENTS_COUNT';

export const EVENTS_SET_READ = 'EVENTS_SET_READ';
export const EVENTS_READ_ALL = 'EVENTS_READ_ALL';

export const STATISTICS_SEND = 'STATISTICS_SEND';

export const GIFT_REQUEST = 'GIFT_REQUEST';
export const GIFT_SUCCESS = 'GIFT_SUCCESS';
export const GIFT_FAIL = 'GIFT_FAIL';

export const GIFT_STORE_SUCCESS = 'GIFT_STORE_SUCCESS';
export const GIFT_STORE_REQUEST = 'GIFT_STORE_REQUEST';
export const GIFT_STORE_FAIL = 'GIFT_STORE_FAIL';

export const GIFT_ORDER_REQUEST = 'GIFT_ORDER_REQUEST';
export const GIFT_ORDER_SUCCESS = 'GIFT_ORDER_SUCCESS';
export const GIFT_ORDER_FAIL = 'GIFT_ORDER_FAIL';

export const GIFT_RECEIPT_REQUEST = 'GIFT_RECEIPT_REQUEST';
export const GIFT_RECEIPT_SUCCESS = 'GIFT_RECEIPT_SUCCESS';
export const GIFT_RECEIPT_FAIL = 'GIFT_RECEIPT_FAIL';

export const GIFT_SEND_RECEIPT_REQUEST = 'GIFT_SEND_RECEIPT_REQUEST';
export const GIFT_SEND_RECEIPT_SUCCESS = 'GIFT_SEND_RECEIPT_SUCCESS';
export const GIFT_SEND_RECEIPT_FAIL = 'GIFT_SEND_RECEIPT_FAIL';

export const GIFT_PURCHASE_REQUEST = 'GIFT_PURCHASE_REQUEST';
export const GIFT_PURCHASE_SUCCESS = 'GIFT_PURCHASE_SUCCESS';
export const GIFT_PURCHASE_FAIL = 'GIFT_PURCHASE_FAIL';

export const GIFT_ASSIGN_REQUEST = 'GIFT_ASSIGN_REQUEST';
export const GIFT_ASSIGN_SUCCESS = 'GIFT_ASSIGN_SUCCESS';
export const GIFT_ASSIGN_FAIL = 'GIFT_ASSIGN_FAIL';

export const GIFT_DATA_SAVE = 'GIFT_DATA_SAVE';

export const PEOPLE_REQUEST = 'PEOPLE_REQUEST';
export const PEOPLE_SUCCESS = 'PEOPLE_SUCCESS';
export const PEOPLE_FAIL = 'PEOPLE_FAIL';
export const PEOPLE_LOAD_MORE_REQUEST = 'PEOPLE_LOAD_MORE_REQUEST';
export const PEOPLE_LOAD_MORE_SUCCESS = 'PEOPLE_LOAD_MORE_SUCCESS';
export const PEOPLE_LOAD_MORE_FAIL = 'PEOPLE_LOAD_MORE_FAIL';

export const PEOPLE_SEARCH_SET_PARAMS = 'PEOPLE_SEARCH_SET_PARAMS';
export const PEOPLE_SEARCH_RESET = 'PEOPLE_SEARCH_RESET';
export const PEOPLE_SET_SEARCH_PARAMS = 'PEOPLE_SET_SEARCH_PARAMS';

export const FAVORITES_REQUEST = 'FAVORITES_REQUEST';
export const FAVORITES_SUCCESS = 'FAVORITES_SUCCESS';
export const FAVORITES_FAIL = 'FAVORITES_FAIL';
export const FAVORITES_LOAD_MORE_REQUEST = 'FAVORITES_LOAD_MORE_REQUEST';
export const FAVORITES_LOAD_MORE_SUCCESS = 'FAVORITES_LOAD_MORE_SUCCESS';
export const FAVORITES_LOAD_MORE_FAIL = 'FAVORITES_LOAD_MORE_FAIL';
export const FAVORITES_ADD_SUCCESS = 'FAVORITES_ADD_SUCCESS';
export const CONTACTS_MARK_CHAT_AS_READ = 'CONTACTS_MARK_CHAT_AS_READ';

export const CONTACTS_FOLDER_TYPE_FAVORITE = 'Favorite';
export const CONTACTS_FOLDER_TYPE_ALL = 'All';

export const CONTACTS_FOLDER_SWITCH = 'CONTACTS_FOLDER_SWITCH';

export const CONTACTS_REQUEST = 'CONTACTS_REQUEST';
export const CONTACTS_SUCCESS = 'CONTACTS_SUCCESS';
export const CONTACT_FEEDBACK_SUCCESS = 'CONTACT_FEEDBACK_SUCCESS';
export const CONTACT_NEXT_UNREAD_SUCCESS = 'CONTACT_NEXT_UNREAD_SUCCESS';
export const CONTACT_REQUEST_ACCEPT_SUCCESS = 'CONTACT_REQUEST_ACCEPT_SUCCESS';
export const CONTACTS_FAIL = 'CONTACTS_FAIL';
export const CONTACTS_LOAD_MORE_REQUEST = 'CONTACTS_LOAD_MORE_REQUEST';
export const CONTACTS_LOAD_MORE_SUCCESS = 'CONTACTS_LOAD_MORE_SUCCESS';
export const CONTACTS_LOAD_MORE_FAIL = 'CONTACTS_LOAD_MORE_FAIL';

export const CONTACTS_FAVORITES_REQUEST = 'CONTACTS_FAVORITES_REQUEST';
export const CONTACTS_FAVORITES_SUCCESS = 'CONTACTS_FAVORITES_SUCCESS';
export const CONTACTS_FAVORITES_FAIL = 'CONTACTS_FAVORITES_FAIL';
export const CONTACTS_FAVORITES_LOAD_MORE_REQUEST =
    'CONTACTS_FAVORITES_LOAD_MORE_REQUEST';
export const CONTACTS_FAVORITES_LOAD_MORE_SUCCESS =
    'CONTACTS_FAVORITES_LOAD_MORE_SUCCESS';
export const CONTACTS_FAVORITES_LOAD_MORE_FAIL =
    'CONTACTS_FAVORITES_LOAD_MORE_FAIL';

export const CONTACT_BLOCK_SUCCESS = 'CONTACT_BLOCK_SUCCESS';
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS';
export const CONTACT_FAVORITE_SUCCESS = 'CONTACT_FAVORITE_SUCCESS';

export const CHAT_REQUEST = 'CHAT_REQUEST';
export const CHAT_SUCCESS = 'CHAT_SUCCESS';
export const CHAT_CLOSE = 'CHAT_CLOSE';
export const CHAT_FAIL = 'CHAT_FAIL';
export const CHAT_HISTORY_REQUEST = 'CHAT_HISTORY_REQUEST';
export const CHAT_HISTORY_SUCCESS = 'CHAT_HISTORY_SUCCESS';
export const CHAT_HISTORY_FAIL = 'CHAT_HISTORY_FAIL';
export const CHAT_SEND_SUCCESS = 'CHAT_SEND_SUCCESS';
export const CHAT_SEND_FAIL = 'CHAT_SEND_FAIL';
export const CHAT_UPLOAD_REQUEST = 'CHAT_UPLOAD_REQUEST';
export const CHAT_UPLOAD_SUCCESS = 'CHAT_UPLOAD_SUCCESS';
export const CHAT_UPLOAD_FAIL = 'CHAT_UPLOAD_FAIL';
export const CHAT_UPLOAD_FILE_SUCCESS = 'CHAT_UPLOAD_FILE_SUCCESS';
export const CHAT_MARK_AS_READED_SUCCESS = 'CHAT_MARK_AS_READED_SUCCESS';
export const CHAT_MARK_MY_MESSAGE_AS_READED = 'CHAT_MARK_MY_MESSAGE_AS_READED';
export const CHAT_NEW_MESSAGE_RECEIVED = 'CHAT_NEW_MESSAGE_RECEIVED';

export const PURCHASE_PRODUCTS_SUCCESS = 'PURCHASE_PRODUCTS_SUCCESS';
export const PURCHASE_PRODUCTS_REQUEST = 'PURCHASE_PRODUCTS_REQUEST';
export const PURCHASE_PRODUCTS_FAIL = 'PURCHASE_PRODUCTS_FAIL';
export const PURCHASE_COINS_SUCCESS = 'PURCHASE_COINS_SUCCESS';
export const PURCHASE_COINS_FAILURE = 'PURCHASE_COINS_FAILURE';
export const PURCHASE_COINS_REQUEST = 'PURCHASE_COINS_REQUEST';

export const ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_FAIL = 'ACCOUNT_DELETE_FAIL';

export const PURCHASE_PAYPAL_CREATE_REQUEST = 'PURCHASE_PAYPAL_CREATE_REQUEST';
export const PURCHASE_PAYPAL_CREATE_SUCCESS = 'PURCHASE_PAYPAL_CREATE_SUCCESS';
export const PURCHASE_PAYPAL_CREATE_FAIL = 'PURCHASE_PAYPAL_CREATE_FAIL';

export const PURCHASE_PAYPAL_CAPTURE_REQUEST =
    'PURCHASE_PAYPAL_CAPTURE_REQUEST';
export const PURCHASE_PAYPAL_CAPTURE_SUCCESS =
    'PURCHASE_PAYPAL_CAPTURE_SUCCESS';
export const PURCHASE_PAYPAL_CAPTURE_FAIL = 'PURCHASE_PAYPAL_CAPTURE_FAIL';

export const PURCHASE_PAYPAL_SUBSCRIPTIONS_REQUEST =
    'PURCHASE_PAYPAL_SUBSCRIPTIONS_REQUEST';
export const PURCHASE_PAYPAL_SUBSCRIPTIONS_SUCCESS =
    'PURCHASE_PAYPAL_SUBSCRIPTIONS_SUCCESS';
export const PURCHASE_PAYPAL_SUBSCRIPTIONS_FAIL =
    'PURCHASE_PAYPAL_SUBSCRIPTIONS_FAIL';

export const PURCHASE_PAYPAL_COINS_REQUEST = 'PURCHASE_PAYPAL_COINS_REQUEST';
export const PURCHASE_PAYPAL_COINS_SUCCESS = 'PURCHASE_PAYPAL_COINS_SUCCESS';
export const PURCHASE_PAYPAL_COINS_FAIL = 'PURCHASE_PAYPAL_COINS_FAIL';

export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';

export const CHAT_TRANSLATE_MESSAGE_REQUEST = 'CHAT_TRANSLATE_MESSAGE_REQUEST';
export const CHAT_TRANSLATE_MESSAGE_SUCCESS = 'CHAT_TRANSLATE_MESSAGE_SUCCESS';
export const CHAT_TRANSLATE_MESSAGE_FAIL = 'CHAT_TRANSLATE_MESSAGE_FAIL';

export const SCREEN_MODE_CHANGE = 'SCREEN_MODE_CHANGE';
export const SERVER_ERROR = 'SERVER_ERROR';
export const DAILY_COINS = 'DAILY_COINS';
export const SET_SHOW_SMART_BANNER = 'SET_SHOW_SMART_BANNER';

export const PW_PAYMENT_URL_REQUEST = 'PW_PAYMENT_URL_REQUEST';
export const PW_PAYMENT_URL_SUCCESS = 'PW_PAYMENT_URL_SUCCESS';
export const PW_PAYMENT_URL_FAIL = 'PW_PAYMENT_URL_FAIL';

export const STRIPE_GET_SESSION_REQUEST = 'STRIPE_GET_SESSION_REQUEST';
export const STRIPE_GET_SESSION_SUCCESS = 'STRIPE_GET_SESSION_SUCCESS';
export const STRIPE_GET_SESSION_FAIL = 'STRIPE_GET_SESSION_FAIL';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';

export const PHOTO_MAKE_PRIVATE_REQUEST = 'PHOTO_MAKE_PRIVATE_REQUEST';
export const PHOTO_MAKE_PRIVATE_SUCCESS = 'PHOTO_MAKE_PRIVATE_SUCCESS';
export const PHOTO_MAKE_PRIVATE_FAIL = 'PHOTO_MAKE_PRIVATE_FAIL';

export const PHOTO_MAKE_PUBLIC_REQUEST = 'PHOTO_MAKE_PUBLIC_REQUEST';
export const PHOTO_MAKE_PUBLIC_SUCCESS = 'PHOTO_MAKE_PUBLIC_SUCCESS';
export const PHOTO_MAKE_PUBLIC_FAIL = 'PHOTO_MAKE_PUBLIC_FAIL';

export const SET_SHOW_INSTALL_APP = 'SET_SHOW_INSTALL_APP';