import React from 'react'
import {ReactComponent as IconLogoApp} from '../Icons/LogoApp.svg';
import {ReactComponent as IconClose} from '../Icons/Close.svg';
import './styles.scss'
import {isAndroid, isIOS} from 'react-device-detect';
import Axios from 'axios';
import {useDispatch} from 'react-redux';
import {setShowSmartBanner} from '../../store/actions/sessionActions';
import __ from '../../utils/translate';

const SmartBanner = () => {
    const dispatch = useDispatch()
    const installApp = () => {
        if (isAndroid) {
            Axios.post(`/endpoints/track-lead-from-web`,).then((response) => {
                if (response.data.result === 'success') {
                    dispatch(setShowSmartBanner(false))
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.loka';
                }
            });
            return;
        }

        Axios.post(`/endpoints/track-lead-from-web`,).then((response) => {
            if (response.data.result === 'success') {
                dispatch(setShowSmartBanner(false))
                window.location.href = 'https://apps.apple.com/app/id1523632672';
            }
        });
    };

    const hideSmartBanner = () => {
        const hoursCountMs = 24 * 3600000
        localStorage.setItem('hideSmartBanner', +Date.now() + hoursCountMs)
        dispatch(setShowSmartBanner(false))
    }

    return <>
        <div className="smart-banner" onClick={() => installApp()}>
            <div className="smart-banner__container">
                <div className="smart-banner__content">
                    <div className="smart-banner__cancel" onClick={(e) => {
                        e.stopPropagation()
                        hideSmartBanner();
                    }}><IconClose/></div>
                    <IconLogoApp style={{width: '20%'}}/>
                    <div className="smart-banner__wrap">
                        <p>{__('LOKA - meet new people')} </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="107" height="18" viewBox="0 0 107 18"
                             fill="none">
                            <path
                                d="M9.5 1L11.5984 6.61181L17.584 6.87336L12.8953 10.6032L14.4962 16.3766L9.5 13.07L4.50383 16.3766L6.10473 10.6032L1.41602 6.87336L7.40161 6.61181L9.5 1Z"
                                fill="#FF9D0C" stroke="#FF9D0C" stroke-linejoin="round"/>
                            <path
                                d="M31.5 1L33.5984 6.61181L39.584 6.87336L34.8953 10.6032L36.4962 16.3766L31.5 13.07L26.5038 16.3766L28.1047 10.6032L23.416 6.87336L29.4016 6.61181L31.5 1Z"
                                fill="#FF9D0C" stroke="#FF9D0C" stroke-linejoin="round"/>
                            <path
                                d="M53.5 1L55.5984 6.61181L61.584 6.87336L56.8953 10.6032L58.4962 16.3766L53.5 13.07L48.5038 16.3766L50.1047 10.6032L45.416 6.87336L51.4016 6.61181L53.5 1Z"
                                fill="#FF9D0C" stroke="#FF9D0C" stroke-linejoin="round"/>
                            <path
                                d="M75.5 1L77.5984 6.61181L83.584 6.87336L78.8953 10.6032L80.4962 16.3766L75.5 13.07L70.5038 16.3766L72.1047 10.6032L67.416 6.87336L73.4016 6.61181L75.5 1Z"
                                fill="#FF9D0C" stroke="#FF9D0C" stroke-linejoin="round"/>
                            <path
                                d="M97.5 1L99.5984 6.61181L105.584 6.87336L100.895 10.6032L102.496 16.3766L97.5 13.07L92.5038 16.3766L94.1047 10.6032L89.416 6.87336L95.4016 6.61181L97.5 1Z"
                                stroke="#FF9D0C" stroke-linejoin="round"/>
                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="88" y="0" width="19"
                                  height="17">
                                <path
                                    d="M97.5 1L99.5984 6.61181L105.584 6.87336L100.895 10.6032L102.496 16.3766L97.5 13.07L92.5038 16.3766L94.1047 10.6032L89.416 6.87336L95.4016 6.61181L97.5 1Z"
                                    fill="#FF9D0C" stroke="#FF9D0C" stroke-linejoin="round"/>
                            </mask>
                            <g mask="url(#mask0)">
                                <rect x="88.1904" y="0.19043" width="11" height="16.1905" fill="#FF9D0C"/>
                            </g>
                        </svg>
                        <p>{__('FREE')} - {isAndroid ? __('on Google Play') : __('on the App Store')}</p>
                    </div>
                </div>
                <div className="smart-banner__btn" onClick={installApp}>{__('Get free Coins in the App!')}</div>
            </div>
        </div>
    </>
}

export {SmartBanner}