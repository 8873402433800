import React, {useRef} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import __ from '../../../../utils/translate'

import {ReactComponent as IconArrowUp} from '../../../../Components/Icons/ArrowUp.svg';
import './styles.scss';

const ChatMessageForm = ({
                             message,
                             handleMessageOnChange,
                             sendMessage,
                             isBlocked
                         }) => {
    const inputRef = useRef(null);
    const onKeyPress = (e) => {
        if (e.key === 'Enter' && e.ctrlKey) {
            return
        }
        if (e.key === 'Enter') {
            sendMessage();
        }
    }

    const clsForm = ['messages-area__form']
    if (isBlocked) {
        clsForm.push('disabled')
    }

    return (
        <div className={clsForm.join(' ')}>
            <div className="messages-form-input form_input">
                <TextareaAutosize
                    onKeyPress={onKeyPress}
                    className="form_textarea_message"
                    rows={1}
                    disabled={isBlocked}
                    value={message}
                    onChange={handleMessageOnChange}
                    placeholder={__('Enter your message...')}
                    ref={inputRef}
                />
                {message ? (
                    <div onClick={sendMessage}>
                        <button className="btn_round send_message">
                            <IconArrowUp/>
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default ChatMessageForm;
