import React from 'react';
import Location from './Location';
import { useDispatch } from 'react-redux';
import { setSearchParams } from '../../../store/actions/peopleSearchActions';

const SearchSettingsLocation = ({showAllSettings}) => {
  const dispatch = useDispatch();

  const setLocation = (item) => {
    dispatch(setSearchParams({
      country_id: item.country_id,
      // region_id: item.region_id,
      // city_id: item.city_id,
      formatted_address: item.formatted_address,
    }))
    showAllSettings()
  };

  return (
    <div className="form_control search_settings_location" style={{ marginBottom: 0 }}>
      <Location
        selectLocation={(location) => setLocation(location)}
        showAllSettings={showAllSettings}
        formControl="true"
      />
    </div>
  );
}

export default SearchSettingsLocation;