import axios from 'axios';
import store from '../store';
import {loginBySecret} from '../store/actions/auth/authActions';
import {API_URL} from '../store/constants';
import {logout} from '../store/actions/auth/logoutActions';
import {showDailyCoins, showServerError} from '../store/actions/eventsActions';

// import i18n from '../utils/i18n';

const jwt = {
  token: null,
  secret: null,
  uid: null,
};

let isWrongToken = false;
let lastWrongErr = null;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function updateAuthToken(data) {
  if (data === undefined) {
    const state = store.getState();
    jwt.token = state.credentials.token;
    jwt.secret = state.credentials.secret;
    jwt.uid = state.credentials.user_id;
  } else {
    jwt.token = data.token;
    jwt.secret = data.secret;
    jwt.uid = data.user_id;
  }
}

function getAuthToken() {
  return new Promise((resolve, reject) => {
    const state = store.getState();
    if (jwt.uid || localStorage.getItem('lk-uid'))  {
      const loginData = {
        secret: jwt.secret || localStorage.getItem('lk-secret'),
        user_id: jwt.uid || localStorage.getItem('lk-uid'),
        lang: state.session.currentLang,
        coordinates: state.session.coordinates,
      };

      if (!loginData.secret) {
        reject();
      }
      store.dispatch(
        loginBySecret(
          loginData,
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          },
        ),
      ); 
    } else {
      reject();
    }
  });
}

function requestInterceptor(config) {
  updateAuthToken();
  const newConfig = {...config};
  newConfig.headers['X-Auth-Token'] =
    jwt.token || localStorage.getItem('lk-token');
  newConfig.headers['X-App-Name'] = 'loka';
  newConfig.headers['X-App-Platform'] = 'web';
  
  return newConfig;
}

async function responseErrorInterceptor(err) {
  const error = err.response;

  if (
    err.toString() === 'Error: Network Error' ||
    err.toString() === 'Error: timeout of 15000ms exceeded'
  ) {
    const networkError = {
      response: {
        data: {
          error: 'network_error',
          error_text: 'Please check your network connection and try again.',
        },
      },
    };
    store.dispatch(showServerError(true));

    return Promise.reject(networkError);
  }

  // Check if response 500 server error.
  if (error.status === 500 || error.status === undefined) {
    return Promise.reject();
  }

  if (error.data.error === 'wrong_secret') {
    return Promise.reject();
  }

  if (error.data.error === 'wrong_token') {
    if (isWrongToken) {
      await sleep(500); // if token is wrong wait 500ms and try
      if (lastWrongErr === error.config.url) return Promise.reject(err);
      lastWrongErr = error.config.url;
      return axios(error.config); // recover requests except the first one
    }
    isWrongToken = true;

    return getAuthToken()
      .then((response) => {
        updateAuthToken(response.data);
        isWrongToken = false;
        return axios(error.config); // Recover 1-st request
      })
      .catch((errResponse) => {
        Promise.reject(errResponse);
        store.dispatch(logout());
      });
  }

  return Promise.reject(err);
}

function responseInterceptor(response) {
  // Check if the server response doesn't json.
  if (!response.headers['content-type'].startsWith('application/json')) {
    return response;
  }
  if (response.data.modals) {
    response.data.modals.forEach(item => {
      if (item.name === 'daily_coins') {
        store.dispatch(showDailyCoins(true));
      }
    });
  }
  return response;
}

axios.defaults.baseURL = API_URL;
axios.defaults.timeout = 15000;
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export default axios;
