import React, {Component} from 'react';
import {Button, Input} from '../../Components/UI';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../store/actions/auth/registrationActions';
import __ from '../../utils/translate';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';

class SetEmailSocial extends Component {
  constructor() {
    super();

    this.state = {
      email: ''
    };
  }

  componentWillUnmount() {
    this.props.registrationActions.clearErrors();
  }

  componentDidMount() {
  }

  hasErrors() {
    return (
      'email' in this.props.registration.errors
    );
  }

  handleOnChange = (event) => {
    this.setState({email: event.target.value});
    this.props.registrationActions.clearErrors();
  };

  handlePressContinue = () => {
    this.props.registrationActions
      .setEmail(this.state.email)
      .then(() => {
        this.props.registrationActions.setEmailSocial(this.state.email).then(() => {
          this.props.history.replace('/personal-info-social', '/set-email-social');
          this.props.registrationActions.setCurrentStep('0');
        });
      }).catch(() => null);
  };

  render() {
    const showBlur =
      this.props.events.showModal || this.props.events.serverError;
    const isMobileMode = this.props.screen.isMobileMode;
    const title = __('Enter your valid email');
    return (
      <main
        className={showBlur ? 'blur' : null}>
        <div className="container">
          <div className="wrapper">
            <div
              className="card_form step_form">
              <div className="left_wrap">
                <div className="top_wrap">
                  <h3 className="step_title mobile_step_title">{title}</h3>
                </div>
              </div>
              <div className="right_wrap">
                <form>
                  <div>
                    <Input
                      invalidBottom={true}
                      invalid={
                        (this.hasErrors() && this.state.email.length < 2) ||
                        this.props.registration.errors.email
                      }
                      error={
                        !this.hasErrors()
                          ? null
                          : this.props.registration.errors.email
                      }
                      value={this.state.email}
                      placeholder={__('Enter your email')}
                      type="text"
                      label={isMobileMode ? '' : __('Your email')}
                      onChange={(event) => this.handleOnChange(event)}
                    />
                  </div>
                  <Button
                      onClick={this.handlePressContinue}
                      disabled={
                        this.hasErrors() || this.state.email.length < 2 ||
                        this.props.registration.isFetching
                      }>
                      {this.props.registration.isFetching
                        ? __('Loading...')
                        : __('Continue')}
                    </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state) => ({
    registration: state.registration,
    events: state.events,
    screen: state.screen,
    myProfile: state.myProfile
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
  }),
)(SetEmailSocial);
