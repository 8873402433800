import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showDailyCoins } from '../../store/actions/eventsActions';
import { ReactComponent as IconClose } from '../Icons/Close.svg';
import { ReactComponent as FiveCoins } from '../Icons/FiveCoins.svg';
import { Button } from '../UI';
import './styles.scss';
import __ from '../../utils/translate';

const DailyCoins = () => {
  const { dailyCoins } = useSelector(state => state.events);
  const dispatch = useDispatch();

  const hideDailyCoins = () => {
    dispatch(showDailyCoins(false));
  };

  return (
    dailyCoins
      ? <div className="server-error">
        <div className="server-error__container">
          <div
            className="server-error__cancel"
            onClick={hideDailyCoins}
          ><IconClose/></div>
          <FiveCoins/>
          <h4 className="server-error__title">
            {__('Your free \n5 daily Coins')}…
          </h4>
          <p className="server-error__text">
            {__('As a Premium user, you’ve earned your 5 daily Coins. Come again tomorrow to get more!')}
          </p>
          <Button
            class="primary"
            onClick={hideDailyCoins}>
            {__('Grab your free Coins')}</Button>
        </div>
      </div>
      : null
  );
};

export default DailyCoins;