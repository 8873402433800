import React, {Component} from 'react';
import {ReactComponent as IconClose} from '../../../Components/Icons/Close.svg';
import {Input} from '../../../Components/UI/Input';
import {Button} from '../../../Components/UI/Button';
import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import __ from '../../../utils/translate';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';
import * as bindRegistrationActions from '../../../store/actions/auth/registrationActions';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import axios from 'axios';

class Login extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);

    this.state = {
      auth: false,
      showSignIn: false,
      email: '',
      password: '',
    };
  }

  goBack() {
    this.props.history.goBack();
    this.props.eventsActions.closeModal(false);
  }

  hasErrors() {
    return (
      'email' in this.props.credentials.errors ||
      'password' in this.props.credentials.errors
    );
  }

  loginHandler = () => {
    const {
      credentials,
      authActions,
      registrationActions,
      registration,
    } = this.props;
    if (
      ((this.state.email === '' || this.state.password === '') &&
        !this.hasErrors()) ||
      credentials.isFetching
    ) {
      return;
    }
    if (registration.inProgress) {
      registrationActions.done();
    }
    const AuthData = {
      email: this.state.email,
      password: this.state.password,
    };
    authActions.login(AuthData).then(() => {
      if (
        this.props.credentials.errors &&
        !this.props.credentials.errors.email
      ) {
        this.props.history.push('/');
      }
    });
  };

  componentDidMount() {
    this.props.authActions.clearErrors();

    setTimeout(() => {
      const token = localStorage.getItem('tokenWeb');
      console.warn('token: ', token);
      axios.interceptors.request.use(
        async (config) => {
          console.warn('Request', config.headers);
          const newConfig = {
            ...config,
            headers: {
              ...config.headers,
              turnstile: token,
            },
          };
          return newConfig;
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    }, 800);
  }

  componentWillUnmount() {
    this.props.authActions.clearErrors();
  }

  handleOnChangeEmail = (event) => {
    this.setState({email: event.target.value});
    this.props.authActions.clearErrors();
  };

  handleOnChangePassword = (event) => {
    this.setState({password: event.target.value});
    this.props.authActions.clearErrors();
  };

  restorePassword = () => {
    this.props.history.push({
      pathname: '/forgot-password',
      state: {modal: true},
    });
  };

  render() {
    if (
      this.props.session.isLogged &&
      this.props.credentials.user_id &&
      !this.props.registration.inProgress
    ) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <div className="popup popup-sign-in">
          <div onClick={this.goBack} className="popup_close">
            <IconClose />
          </div>
          <p className="popup_title">{__('Sign in')}</p>
          <div className="wrap_input">
            <Input
              invalid={this.hasErrors()}
              value={this.state.email}
              placeholder={__('Enter your email')}
              type="email"
              extraСlass="mb20"
              label={__('Your email')}
              onChange={(event) => this.handleOnChangeEmail(event)}
            />

            <Input
              invalid={this.hasErrors()}
              value={this.state.password}
              placeholder={__('Enter your password')}
              type="password"
              label={__('Your password')}
              onChange={(event) => this.handleOnChangePassword(event)}
            />
            {!this.hasErrors() ? null : (
              <p className="validation_label validation_label_signin">
                {this.props.credentials.errors.email}
              </p>
            )}
          </div>

          <Button
            onClick={this.loginHandler}
            disabled={
              ((this.state.email === '' || this.state.password === '') &&
                !this.hasErrors()) ||
              this.props.credentials.isFetching
            }>
            {this.props.credentials.isFetching
              ? __('Loading...')
              : __('Continue')}
          </Button>

          <div className="btn_link mt30" onClick={this.restorePassword}>
            {__('Restore password')}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      registration: state.registration,
      credentials: state.credentials,
      session: state.session,
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
      registrationActions: bindActionCreators(
        bindRegistrationActions,
        dispatch,
      ),
      authActions: bindActionCreators(bindAuthActions, dispatch),
    }),
  )(Login),
);
