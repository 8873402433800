import React from 'react';
import {
  CONTACTS_FOLDER_TYPE_ALL,
  CONTACTS_FOLDER_TYPE_FAVORITE,
} from '../../../../store/constants';
import __ from '../../../../utils/translate';

const SwitchFolderButtons = ({activeFolder, switchFolder}) => {
  const renderButton = (folderType) => {
    const isActive = activeFolder === folderType;
    const clsTabs = ['messages-tab'];
    if (isActive) {
      clsTabs.push('active');
    }
    return (
      <div
        onClick={() => switchFolder(folderType)}
        className={clsTabs.join(' ')}>
        {folderType === CONTACTS_FOLDER_TYPE_FAVORITE
          ? __('Favorites')
          : __('All')}
      </div>
    );
  };

  return (
    <div className="messages-tabs">
      {renderButton(CONTACTS_FOLDER_TYPE_ALL)}
      {renderButton(CONTACTS_FOLDER_TYPE_FAVORITE)}
    </div>
  );
};

export default SwitchFolderButtons;
