import {combineReducers} from 'redux';

import session from './sessionReducer';
import credentials from './auth/loginReducer';
import myProfile from './myProfileReducer';
import profile from './profileReducer';
import registration from './auth/registrationReducer';
import upload from './uploadReducer';
import people from './peopleReducer';
import peopleSearch from './peopleSearchReducer';
import favorites from './favoritesReducer';
import contacts from './contactsReducer';
import chat from './chatReducer';
import events from './eventsReducer';
import notifications from './notificationsReducer';
import purchase from './purchaseReducer';
import screen from './screenReducer';

const appReducer = combineReducers({
  session,
  credentials,
  myProfile,
  registration,
  upload,
  people,
  peopleSearch,
  profile,
  favorites,
  contacts,
  chat,
  events,
  notifications,
  purchase,
  screen
});

export default (state, action) => {
  let newState = state;
  if (action.type === 'LOGOUT_SUCCESS') {
    newState = undefined;
  }

  return appReducer(newState, action);
};
