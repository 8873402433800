import React, {Component} from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {ReactComponent as IconPeople} from '../../Icons/People.svg';
import {ReactComponent as IconFavorites} from '../../Icons/Favorites.svg';
import {ReactComponent as IconNotification} from '../../Icons/Notification.svg';
import {ReactComponent as IconMessages} from '../../Icons/Messages.svg';
import {ReactComponent as IconProfile} from '../../Icons/Profile.svg';
import {ReactComponent as IconPeopleActive} from '../../Icons/PeopleActive.svg';
import {ReactComponent as IconFavoritesActive} from '../../Icons/FavoritesActive.svg';
import {ReactComponent as IconProfileActive} from '../../Icons/ProfileActive.svg';
import {ReactComponent as IconMessagesActive} from '../../Icons/MessagesActive.svg';
import {ReactComponent as IconNotificationActive} from '../../Icons/NotificationActive.svg';
import {Backdrop} from '../../UI';
import {connect} from 'react-redux';
import Notifications from '../../../Containers/Notifications';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../../store/actions/eventsActions';
import __ from '../../../utils/translate'

class BottomMainMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: false,
    };
  }

  openNotification = () => {
    this.setState({
      showNotification: true,
    });
    this.props.eventsActions.openModal(this.state.showNotification);
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    });
    this.props.eventsActions.closeModal(this.state.showNotification);
  };

  componentDidMount() {
  }

  render() {
    const clsNotification = ['bottom-menu__item'];
    const clsActiveControl = ['bottom-menu__item']

    if (this.state.showNotification) {
      clsNotification.push('active');
      clsActiveControl.push('disable');
    }

    if (this.props.events.results.filter(it => it.is_read === false).length > 0) {
      clsNotification.push('new-notifications');
    }
    return (
      <>
        <div className="bottom-menu-wrap">
          <div className="container">
            <div className="bottom-menu">
                
                    <NavLink
                    to="/"
                    exact={true}
                    onClick={this.closeNotification} 
                    activeClassName="active"
                    className={clsActiveControl.join(' ')}>
                    <span className="no-active">
                        <IconPeople />
                    </span>
                    <span className="active">
                        <IconPeopleActive />
                    </span>
                    <span className="bottom-menu__text">{__('People')}</span>
                    </NavLink>
                
                    <NavLink
                    onClick={this.closeNotification} 
                    to="/favorites"
                    activeClassName="active"
                    className={clsActiveControl.join(' ')}>
                    <span className="no-active">
                        <IconFavorites />
                    </span>
                    <span className="active">
                        <IconFavoritesActive />
                    </span>
                    <span className="bottom-menu__text">{__('Favorites')}</span>
                    </NavLink>
                
                    <div onClick={this.openNotification} className={clsNotification.join(' ')}>
                    <span className="no-active">
                        <IconNotification />
                    </span>
                    <span className="active">
                        <IconNotificationActive />
                    </span>
                    <span className="bottom-menu__text">{__('Notifications')}</span>
                    </div>
                
                    <NavLink
                    to="/messages"
                    activeClassName="active"
                    onClick={this.closeNotification} 
                    className={clsActiveControl.join(' ')}>
                    {this.props.session.unreadMessageCount ? (
                        <span className="count-new-messages">{this.props.session.unreadMessageCount}</span>
                    ) :null}
                    <span className="no-active">
                        <IconMessages />
                    </span>
                    <span className="active">
                        <IconMessagesActive />
                    </span>
                    <span className="bottom-menu__text">{__('Messages')}</span>
                    </NavLink>
                
                
                    <Link
                    to={{
                        pathname: '/my-profile',
                        state: {modal: true},
                    }}
                    onClick={this.closeNotification} 
                    className={clsActiveControl.join(' ')}>
                    <span className="no-active">
                        <IconProfile />
                    </span>
                    <span className="active">
                        <IconProfileActive />
                    </span>
                    <span className="bottom-menu__text">{__('Profile')}</span>
                    </Link>
                
            </div>
          </div>
        </div>

        {this.state.showNotification ? (
          <>
            <Notifications close={this.closeNotification}/>
            <Backdrop onClick={this.closeNotification} />
          </>
        ) : null}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      events: state.events,
      registration: state.registration,
      session: state.session
    }),
    (dispatch) => ({
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(BottomMainMenu),
);
