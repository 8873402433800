import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import {Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindEventsActions from '../../store/actions/eventsActions';
import Profile from '../Profile';
import MyProfile from '../MyProfile';
import EditProfile from '../EditProfile';
import Search from '../People/Search';
import MyProfileSettings from '../MyProfileSettings';
import Login from '../Auth/Login';
import AddCoins from '../AddCoins';
import GetPremium from '../GetPremium';
import Chat from '../Chat';
import GetPremiumPayment from '../GetPremiumPayment';
import GetTrial from '../GetTrial';
import AddCoinsPayment from '../AddCoinsPayment';
import RestorePassword from '../Auth/RestorePassword';
import ForgotPassword from '../Auth/ForgotPassword';
import modalRouteService from '../../services/ModalRouteService';

const modalStyle = {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.7)',
    zIndex: '99',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

class Modal extends Component {
    goBackStack = [];

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.eventsActions.closeModal(false);
        modalRouteService.removePath();
        if (this.props.match.url === '/get-premium') {
            this.props.history.push({pathname: '/add-coins', state: {modal: true, go: true}});
            return;
        }
        if (modalRouteService.currentPath()) {
            if (modalRouteService.currentPath() === '/chat') {
                this.props.history.goBack();
            } else {
                this.props.history.replace({pathname: modalRouteService.currentPath()});
            }
        } else {
            if (this.props.location.state && this.props.location.state.initialRoute) {
                this.props.history.replace({pathname: this.props.location.state.initialRoute});
            } else {
                this.props.history.replace({pathname: '/'});
            }
            modalRouteService.removeAllStack();
        }
    }

    componentDidMount() {
        document.body.classList.add("body-overflow-hidden");
        this.props.eventsActions.openModal(true);

        if (!this.props.session.isLogged && ![`/login`, `/forgot-password`, `/restore-password`].includes(this.props.match.url)) {
            this.props.eventsActions.closeModal(false);
            this.props.history.replace({pathname: '/'});
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("body-overflow-hidden");
    }

    render() {
        const id = parseInt(this.props.match.params.id);
        const {session, credentials} = this.props;
        return createPortal(
            <div style={modalStyle} onClick={this.goBack}>
                <div onClick={(e) => e.stopPropagation()} className="popup_body__container">
                    {!isNaN(id) ? (
                        session.isLogged && credentials.user_id ?
                            <Route path={`/:id`} exact component={Profile}/> : null
                    ) : (
                        session.isLogged && credentials.user_id ? (
                            <>
                                <Route path={`/my-profile`} exact component={MyProfile}/>
                                <Route path={`/edit-profile`} exact component={EditProfile}/>
                                <Route path={`/get-trial`} exact component={GetTrial}/>
                                <Route path={`/get-payment`} exact component={GetPremiumPayment}/>
                                <Route path={`/get-payment-coins`} exact component={AddCoinsPayment}/>
                                <Route path={`/search`} exact component={Search}/>
                                <Route path={`/my-profile-settings`} exact component={MyProfileSettings}/>
                                <Route path={`/get-premium`} exact component={GetPremium}/>
                                <Route path={`/add-coins`} exact component={AddCoins}/>
                                <Route path={`/chat`} exact component={Chat}/>
                            </>
                        ) : (
                            <>
                                <Route path={`/login`} exact component={Login}/>
                                <Route path={`/forgot-password`} exact component={ForgotPassword}/>
                                <Route path={`/restore-password`} exact component={RestorePassword}/>
                            </>
                        )
                    )}
                </div>
            </div>,
            document.getElementById('modal_root')
        );
    }
}

export default withRouter(
    connect(
        (state) => ({
            events: state.events,
            session: state.session,
            credentials: state.credentials,
        }),
        (dispatch) => ({
            eventsActions: bindActionCreators(bindEventsActions, dispatch),
        }),
    )(Modal),
);
