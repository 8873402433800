import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ReactComponent as IconClose } from '../../Components/Icons/Close.svg';
import { bindActionCreators } from 'redux';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import { ReactComponent as ThreeCoins } from '../../Components/Icons/ThreeCoins.svg';
import { ReactComponent as TwoCoins } from '../../Components/Icons/TwoCoins.svg';
import { ReactComponent as TrialIconOne } from '../../Components/Icons/TrialIconOne.svg';
import './styles.scss';
import __ from '../../utils/translate';
import { PAYMENT_TYPE } from '../../store/constants';
import modalRouteService from '../../services/ModalRouteService';

class GetTrial extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.state = {};
  }

  handleOnClick = () => {
    this.props.eventsActions.closeModal(false);
  };

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
  }

  goBack() {
    this.props.eventsActions.closeModal(false);
    document.removeEventListener('keydown', this.escFunction, false);
    this.props.history.push({pathname: '/add-coins', state: {modal: true, go: true}})
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.goBack();
    }
  }

  choosePayment(plan_id, productId) {
    if (this.props.credentials && this.props.credentials.user_id === 136810) {
      this.props.purchaseActions.getPaymentUrl(productId)
      this.props.history.push({
        pathname: '/get-payment',
        state: { modal: true, planId: plan_id, productId },
      });

      return
    }

    switch (PAYMENT_TYPE) {
      case 'pw': {
        this.props.purchaseActions.getPaymentUrl(productId)
        this.props.history.push({
          pathname: '/get-payment',
          state: { modal: true, planId: plan_id, productId },
        })
        break
      }
      case 'paypal': {
        this.props.history.push({
          pathname: '/get-payment',
          state: { modal: true, planId: plan_id, productId },
        })
        break
      }
      case 'stripe': {
        this.props.purchaseActions.stripeGetSession(productId)
        break
      }
      default: {
        this.props.purchaseActions.getPaymentUrl(productId)
        this.props.history.push({
          pathname: '/get-payment',
          state: { modal: true, planId: plan_id, productId },
        })
      }
    }
  }

  render() {
    const { isFetching, tariffs } = this.props.purchase;
    return (
      <div className="popup_body popup-purchase popup-trial">
        <div className="popup-purchase__head">
          <div className="btn_close" onClick={this.goBack}>
            <IconClose/>
          </div>
          <h1 className="popup-purchase__title">{__('Try free for 7 days')}</h1>
          <p className="popup-purchase__description">{__('Try Premium experience free.')}<br/>
            {__('No commitment - cancel any time')}</p>
        </div>
        <div className="popup-purchase__content-trial popup_content scroll">
          <div className="trial-list">
            <div className="trial-list__item">
              <div className="trial-list__icon">
                <TrialIconOne />
              </div>
              <div className="trial-list__text">{__('Unlimited new contacts per day')}</div>
            </div>
            <div className="trial-list__item">
              <div className="trial-list__icon">
                <ThreeCoins/>
              </div>
              <div className="trial-list__text">{__('50 free Coins every week')}</div>
            </div>
            <div className="trial-list__item">
              <div className="trial-list__icon">
                <TwoCoins/>
              </div>
              <div className="trial-list__text">{__('5 free Сoins every day you come')}</div>
            </div>
          </div>
          {isFetching ? __('Loading...') : (
            tariffs.subscriptions && tariffs.subscriptions.length ? (
              tariffs.subscriptions.sort(function (a, b) {
                return a.value.month - b.value.month;
              }).map((product, i) => (
                <>
                  {i === 0 && product.type === 'trial' ? (
                    <div
                      className="btn btn_trial"
                      key={i}
                      onClick={() => this.choosePayment(product.plan_id, product.id)}
                    >
                      {__('Try Free')}
                    </div>
                  ) : null}
                </>
              ))
            ) : 'error'
          )}
        </div>
        <div className="popup-purchase__agree">
          <Link to="/terms" onClick={() => this.handleOnClick()}>
            {__(
              'Terms of Service {{and}} Privacy Policy',
              {
                and: (
                  <span>{__('and')}</span>
                ),
              },
            )}
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => ({
    purchase: state.purchase,
    credentials: state.credentials,
  }),
  (dispatch) => ({
    purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
    myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
    eventsActions: bindActionCreators(bindEventsActions, dispatch),
  }),
)(GetTrial));
