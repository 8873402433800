import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindRegistrationActions from '../../store/actions/auth/registrationActions';
import * as bindUploadActions from '../../store/actions/uploadActions';
import * as bindSessionActions from '../../store/actions/sessionActions';
import * as bindLocationByIpActions from '../../store/actions/auth/locationByIpActions';
import __ from '../../utils/translate';

class SetPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURI: '',
      inProgress: false,
      image: null,
      widthImg: '',
      heightImg: '',
    };
  }

  componentDidMount() {
    const {sessionActions, locationByIpActions} = this.props;
    if ('geolocation' in navigator) {
      sessionActions.getCurrentPosition()
        .catch(() => locationByIpActions.getLocationByIp());
    } else {
      locationByIpActions.getLocationByIp();
    }
  }

  skip = () => {
    this.props.history.push('/activities-occupation/activity');
    this.props.registrationActions.setCurrentStep('4');
  };

  handlePressContinue = () => {
    this.skip();
  };

  fileSelectedHandler = (e) => {
    if (e.target.files.length) {
      const imageURI = URL.createObjectURL(e.target.files[0]);
      const image = e.target.files[0];
      this.setState(
        {
          imageURI,
          image,
        },
        () => {
          const photo = {
            uri: this.state.imageURI,
            image: this.state.image,
            albumType: 'Default',
            isDocument: true,
          };
          this.props.uploadActions
            .upload([photo])
            .then(() => {
              if (
                this.props.upload.error === '' &&
                !this.props.upload.isFetching
              ) {
                this.handlePressContinue();
              }
            })
            .catch(() => null);
        },
      );
    }
  };

  render() {
    const upload = this.props.upload;
    const isMobileMode = this.props.screen.isMobileMode;
    return (
      <main
        id="main"
        style={
          this.props.screen.isMobileMode
            ? {
                paddingTop: 0,
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }
            : {}
        }>
        <div className="container">
          <div className="wrapper">
            <div
              className="card_form step_form"
              style={
                this.props.screen.isMobileMode
                  ? {boxShadow: 'none', padding: 0}
                  : {}
              }>
              <div className="left_wrap">
                <div className="top_wrap">
                  <h3 className="step_title mobile_step_title">
                    {__('Your best photo')}
                  </h3>
                  <p className="step_text mobile_step_text">
                    {__('Pick the one you would like other people to see')}
                  </p>
                </div>
                {!isMobileMode ? (
                  <div onClick={this.skip} className="step_skip btn_link">
                    {__('Skip this step')}
                  </div>
                ) : null}
              </div>
              <div className="right_wrap">
                <label htmlFor="upload-button">
                  {this.state.imageURI && !upload.isFetching ? (
                    <>
                      <div className="upload-photo">
                        <img
                          src={this.state.imageURI}
                          alt=""
                          id="uploaded_ph"
                        />
                      </div>
                      {upload.error === '' ? null : (
                        <>
                          <p className="upload-photo-error">{upload.error}</p>
                          <span>
                            <button
                              className="btn btn_form"
                              onClick={() => this.fileInput.click()}>
                              {__('Сhoose another file')}
                            </button>
                            <input
                              type="file"
                              id="upload-button"
                              style={{display: 'none'}}
                              onChange={this.fileSelectedHandler}
                              ref={(fileInput) => (this.fileInput = fileInput)}
                            />
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <span>
                      <button
                        className="btn_photo"
                        onClick={() => this.fileInput.click()}>
                        {!upload.isFetching ? (
                          <svg
                            height="90"
                            viewBox="0 0 100 90"
                            width="100"
                            xmlns="http://www.w3.org/2000/svg">
                            <g fill="none">
                              <path
                                d="M88.281 14.026h-14.37c-.455 0-.865-.248-1.076-.648L68.86 5.045l-.034-.068C67.288 1.907 64.193 0 60.752 0H39.527c-3.442 0-6.536 1.907-8.075 4.978-.012.022-.023.045-.033.068l-3.976 8.332c-.211.4-.62.648-1.076.648H11.72C5.257 14.026 0 19.269 0 25.714v52.598C0 84.757 5.257 90 11.719 90H88.28C94.743 90 100 84.757 100 78.312V25.714c0-6.445-5.257-11.688-11.719-11.688z"
                                fill="#C3D2DB"
                              />
                              <path
                                d="M50 25c13.785 0 25 11.215 25 25S63.785 75 50 75 25 63.785 25 50s11.215-25 25-25zm0 2c-12.682 0-23 10.318-23 23s10.318 23 23 23 23-10.318 23-23-10.318-23-23-23zM76 29H84V37H76z"
                                fill="#FFF"
                              />
                            </g>
                          </svg>
                        ) : (
                          __('Loading...')
                        )}
                      </button>
                      {!isMobileMode ? (
                        <button
                          disabled={upload.isFetching}
                          className="btn btn_form"
                          onClick={() => this.fileInput.click()}>
                          {__('Choose file')}
                        </button>
                      ) : null}
                      <input
                        type="file"
                        id="upload-button"
                        style={{display: 'none'}}
                        onChange={this.fileSelectedHandler}
                        ref={(fileInput) => (this.fileInput = fileInput)}
                      />
                    </span>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
        {isMobileMode ? (
          <div style={{paddingLeft: 15, paddingRight: 15}}>
            <button
              disabled={upload.isFetching}
              className="btn btn_form"
              onClick={() => this.fileInput.click()}>
              {__('Choose file')}
            </button>
          </div>
        ) : null}
      </main>
    );
  }
}

export default connect(
  (state) => ({
    registration: state.registration,
    upload: state.upload,
    screen: state.screen,
  }),
  (dispatch) => ({
    registrationActions: bindActionCreators(bindRegistrationActions, dispatch),
    uploadActions: bindActionCreators(bindUploadActions, dispatch),
    sessionActions: bindActionCreators(bindSessionActions, dispatch),
    locationByIpActions: bindActionCreators(bindLocationByIpActions, dispatch),
  }),
)(SetPhoto);
