import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import {ReactComponent as IconSelectedCheck} from '../Icons/SelectedCheck.svg';
import {ReactComponent as ArrowDown} from '../Icons/ArrowDown.svg';
import __ from '../../utils/translate';
import './style.scss'

const LanguagesField = ({languages, selectLanguage, selectedLanguages}) => {
  const [selectMenu, setSelectMenu] = useState(false);
  const showSelectMenu = () => {
    setSelectMenu(!selectMenu);
  };
  const useOutsideStoragesForm = (ref) => {
    useEffect(() => {
      /**
       * If clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSelectMenu(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideStoragesForm(wrapperRef);
  return (
    <div className="form_control mb20">
      <div className="form_label">{__('Languages you speak')}</div>
      <div className="lang_select"  ref={wrapperRef}>
        <div className="lang_select-wrap form_input" onClick={showSelectMenu}>
          <div className="lang_selected">
            {selectedLanguages && selectedLanguages.length ?
              selectedLanguages.map((lang, i) => (
                <span key={lang.id}>{lang.name}{i < selectedLanguages.length - 1 ? ', ' : null}</span>
              ))
             : null}
          </div>

          <ArrowDown/>
        </div>
        {selectMenu ? (
          <div className="lang_select-menu select-menu">
            <p className="select-menu__title">{__('Languages you speak')}</p>
            <Scrollbars
              autoHeight
              autoHeightMin={20}
              autoHeightMax={'200px'}>
              {languages && languages.length ? (
                languages.map((lang, i) => (
                  <div className={languages.filter(it => selectedLanguages.indexOf(it.id) > -1)[i] !== selectedLanguages.find(it => it.id === lang.id) ? 'select-menu__item active' : 'select-menu__item'} key={lang.id}
                     onClick={(e) => {
                       e.preventDefault()
                       selectLanguage(lang);
                     }}
                  >
                    <div>
                      <div className="select-menu__value">{lang.name}</div>
                    </div>
                    <div className="select-menu__check">
                      <IconSelectedCheck/>
                    </div>
                  </div>
                ))
              ) : null}
            </Scrollbars>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LanguagesField;